import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { getRating } from '@/helpers/statusIncident.js';
import TicketItem from '@/components/request/elements/TicketItem/TicketItem.vue';
import TicketComments from '@/components/request/elements/TicketComments/TicketComments.vue';
import { getIncidentStatus } from '@/helpers/statusIncident.js';
import { showError } from '@/helpers/globalHelpers';
import RatingDialog from '@/components/request/elements/RatingDialog/RatingDialog.vue';
import ReassignUserDialog from '../../request/elements/ReassignUserDialog';
import TicketDetailElement from '@/components/request/elements/TicketDetailElement/TicketDetailElement.vue';


export default {
	name: 'TicketConfirm',
	data() {
		return {
			reassignUserDialog: false,
			source: 'request-detail',
			breadcrumbs: [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Incidentes',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'IncidentsHome',
					},
				},
				{
					text: 'Solicitudes de Tickets',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'RequestList',
					},
				},
				{
					text: 'Detalle de Ticket',
					link: true,
					exact: true,
					disabled: true,
				},
			],
			statusList: [
				{ text: 'Solicitado', value: 'Solicitado' },
				{ text: 'Asignado', value: 'Asignado' },
				{ text: 'Resolución', value: 'Resolución' },
				{ text: 'Consulta', value: 'Consulta' },
				{ text: 'Cerrado', value: 'Cerrado' },
			],
			loading: false,
			filters: {
				q: null,
				page: 1,
				area: null,
				status: null,
				pagination: true,
				per_page: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
			},
			// mode: 'REQUEST', // TICKETSTARTED,REQUEST
			isRatingActive: false,
		};
	},

	created() {
		this.getRequestId();
		this.getResponsibles();
	},

	computed: {
		...mapState('ticket', ['ticket']),
		...mapState('auth', ['user']),
		hasStartTicketPermission() {
			return this.$store.getters['auth/hasActivity'](
				'solicitudes',
				'detalleSolicitud',
				'iniciarTicket'
			);
		},
		hasCommentPermission() {
			return this.$store.getters['auth/hasActivity'](
				'solicitudes',
				'detalleSolicitud',
				'escribirComentarios'
			);
		},
		isColumns() {
			return (
				this.ticket?.ticket_attention &&
				!['SOLICITADO', 'CANCELADO'].includes(this.ticket?.status)
			);
		},
		showDetailTicketConfirm() {
			if (this.ticket && this.user && this.ticket?.ticket_user_confirm?.some(ticketUserConfirm => ticketUserConfirm?.user_confirm === this.user.id)) {
				return true;
			} else {
				return false;
			}
		}
	},

	methods: {
		getIncidentStatus,
		...mapActions('user', ['getResponsibles']),
		...mapActions('ticket', ['getTicketById', 'cleanTicket', 'ticketConfirm']),
		...mapActions('ticketAttention', ['createTicketAttention']),
		getRating,
		changeFilter() { },

		...mapActions('ticketComment', [
			'listTicketComments',
		]),

		async getTicketComments() {
			const payload = {
				company_id: this.ticket?.company_id,
				ticket_attention: this.ticket?.ticket_attention.id,
			};
			const { error } = await this.listTicketComments(payload);
			if (error) {
				showError(error);
			}
		},

		confirmTicket() {
			this.$swal({
				icon: 'question',
				title: 'Confirmación de Ticket',
				text: '¿Estás seguro de que deseas confirmar este ticket?',
				showCancelButton: true,
				confirmButtonText: 'Sí, confirmar',
				cancelButtonText: 'Cancelar',
				confirmButtonColor: '#0039a6',
				cancelButtonColor: 'grey',
			}).then(async (result) => {
				if (result.isConfirmed) {
					// Iniciar Ticket / Crear Ticket-Attention
					await this.confirmedTicket();
					// this.mode = 'TICKETSTARTED';
				}
			});
		},

		async getRequestId() {
			try {
				this.loading = true;
				const id = this.$route.params.incidentId;
				await this.getTicketById(id);
				this.loading = false;
			} catch (ex) {
				console.log(ex);
			}
		},

		async confirmedTicket() {
			const { ok, error } = await this.ticketConfirm(this.ticket.id);
			if (ok) {
				await this.getTicketComments();
				this.getRequestId();
			} else {
				showError(error);
			}
		},

		formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},

		downloadFile(filePath) {
			const fileName = filePath.split('/').pop();
			let a = document.createElement('a');
			a.href = filePath;
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		},
		printSubtype(item) {
			return (
				item?.subtype?.classification?.area?.name +
				' / ' +
				item?.subtype?.classification?.name +
				' / ' +
				item?.subtype?.name
			);
		},
		printAssets(assets) {
			let assetsText = [];
			if (Array.isArray(assets)) {
				assets.forEach((asset) => {
					assetsText.push(asset?.name + ' ' + asset?.serial_number);
				});
			}
			return assetsText.join(', ');
		},

		setTicketStatus(value) {
			this.ticket.status = value;
		},

		getNamefile(url) {
			try {
				const urlParts = url.split('/');
				const fileNameWithTimestamp = urlParts[urlParts.length - 1];
				const fileNameParts = fileNameWithTimestamp.split('-');
				fileNameParts.shift();
				const fileName = fileNameParts.join('-');
				return fileName;
			} catch (error) {
				return 'EVIDENCIA';
			}
		},

		reassignUser() {
			//this.item = Object.assign({}, request);
			this.reassignUserDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
	},
	beforeDestroy() {
		this.cleanTicket();
	},
	components: {
		TicketItem,
		TicketComments,
		RatingDialog,
		ReassignUserDialog,
		TicketDetailElement
	},
};
