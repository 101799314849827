import { mapState, mapActions } from 'vuex';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
export default {
	name: 'RegisterSpareDialog',
	data() {
		return {
			dialog: false,
			listError: [],
			valid: false,
			loadingSave: false,
			sparesForm: {
				name: '',
				area: null,
				serial_number: '',
				spare_type: null,
				cost: null,
			},
			nameRules: [
				(v) => !!v || 'Nombre es requerido',
				(v) => v.length <= 50 || 'Nombre debe tener menos de 50 caracteres',
			],
			areaRules: [(v) => !!v || 'Área es requerido'],
			serieRules: [
				(v) => !!v || 'Número de serie es requerido',
				(v) => v.length <= 50 || 'Número de serie debe tener menos de 50 caracteres',
			],
			typeRules: [(v) => !!v || 'Tipo de repuesto es requerido'],
			costRules: [
				(v) => !!v || 'Costo es requerido',
				(v) => this.checkIntegerPart(v) || 'La parte entera debe tener un máximo de 6 dígitos',
				(v) => /^[0-9]*(\.[0-9]{0,2})?$/.test(v) || 'Solo se permiten números con hasta dos decimales'
			],
		};
	},
	computed: {
		...mapState({
			companies: (state) => state.auth.user?.companies,
		}),
		...mapState({
			responsibles: (state) => state.security.usersActive,
		}),
		...mapState('area', ['allAreas']),
		...mapState('spareType', ['allSpareTypes']),
	},
	created() {
		this.getUsersActive();
	},
	methods: {
		...mapActions('security', ['getUsersActive']),
		...mapActions('spare', ['createSpare']),

		checkIntegerPart(value) {
			if (!value) {
				return true;
			}
			const stringValue = value.toString();
			const integerPart = stringValue.split('.')[0];
			return integerPart.length <= 6;
		},

		dialogInput(value) {
			// this.$refs.button.$el.blur();
			// this.dialog = value;
			this.$nextTick(() => {
				this.$refs.button.$el.blur();
				this.$refs.form.resetValidation();
				this.dialog = value;
				if (!value) {
					this.sparesForm = {
						name: '',
						area: null,
						serial_number: '',
						spare_type: null,
						cost: null,
					},
						this.listError = [];
				}
			})
		},
		async save() {
			if (!this.$refs.form.validate()) {
				return;
			}
			this.listError = [];
		},
		close() {
			this.dialog = false;
			this.sparesForm = {
				name: '',
				area: null,
				serial_number: '',
				spare_type: null,
				cost: null,
			};
			this.listError = [];
			this.$refs?.form?.resetValidation();
		},
	},
	components: {
		ValidationAlert,
	},
};