<template>
	<ReturnMultipleAssignments />
</template>
<script>
import ReturnMultipleAssignments from '@/components/inventory/assets/MultipleAssignments/ReturnMultipleAssignments/ReturnMultipleAssignments.vue';

export default {
	name: 'ReturnMultipleAssignmentsPage',
	components: { ReturnMultipleAssignments },
};
</script>
