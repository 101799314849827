<template>
	<DetailMaintenance />
</template>
<script>
import DetailMaintenance from '@/components/inventory/elements/DetailMaintenance/index.vue';

export default {
	name: 'DetailMaintenancePage',
	components: { DetailMaintenance },
};
</script>
