import { showError } from '@/helpers/globalHelpers';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: 'DevolutionAsset',
  data() {
    return {
      isLoading: false,
      listError: [],
      dateSignature: null,
      //

    };
  },
  created() {
    this.getAssetAssignmentId();
  },
  computed: {
    ...mapState('assetAssign', ['assetAssign']),
    ...mapState('assetAssignment', ['assetAssignmentReturn']),

    imagesEvidence() {
      const images = [];
      this.assetAssignmentReturn?.assetAssignReturnDetails?.forEach(detail => {
        detail.evidence_files.forEach(file => {
          if (file.evidence_file.includes('.png') ||
              file.evidence_file.includes('.jpg') ||
              file.evidence_file.includes('.jpeg')) {
            images.push(file.evidence_file);
          }
        });
      });
      return images;
    },

    documentsEvidence() {
      const documents= [];
      this.assetAssignmentReturn?.assetAssignReturnDetails?.forEach(detail => {
        detail.evidence_files.forEach(file => {
          if (file.evidence_file.includes('.docx') ||
          file.evidence_file.includes('.pdf') ||
          file.evidence_file.includes('xlsx')) {
                documents.push(file.evidence_file);
          }
        });
      });
      return documents;
    },

    lastSignerComputed() {
      return this.assetAssignmentReturn?.assetAssignSignerReturn?.find(res =>
        res.status === 'PENDIENTE'
      );
    },
  },
  methods: {
    ...mapActions('assetAssign', ['confirmReturnAsset', 'getAssetReturnCertificate']),

    ...mapActions('assetAssignment', ['getAssignmentReturn','confirmReturnAsset','getAssetAssignmentReturnCertificate']),

    async getAssetAssignmentId() {
      this.isLoading = true;
      this.dateSignature = moment().format();
      const { assetAssignId } = this.$route.params;

      const { error } = await this.getAssignmentReturn(assetAssignId);

      if (error) showError(error);

      this.isLoading = false;
    },

    formatDate(dateString) {
      if (!dateString) {
        return '-';
      }
      return moment(dateString).format('DD/MM/YYYY HH:mm');
    },

    async confirmReturn() {
      this.$swal({
        text: '¿Estás seguro de confirmar la devolución?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0039a6',
        cancelButtonColor: 'grey',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sí, confirmar',
      }).then(async result => {
        if (result.isConfirmed) {
          this.isLoading = true;
          const { error } = await this.confirmReturnAsset({ id: this.assetAssignmentReturn.id });
          if (error) showError(error);
          this.dateSignature = null
          await this.getAssetAssignmentId()
          this.isLoading = false;
        }
      });
    },

    async downloadCertificateAssetReturn() {
      try {
        const { assetAssignId } = this.$route.params; // Asumiendo que obtienes el ID desde los parámetros de la ruta
        const { ok, response } = await this.getAssetAssignmentReturnCertificate({ id: assetAssignId, data: Intl.DateTimeFormat().resolvedOptions().timeZone });
        if (ok) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'acta_devolución.pdf'); // O el nombre que prefieras
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        showError(error);
        // Manejo de errores, por ejemplo mostrar un mensaje al usuario
      }
    },
  }
}