<template>
	<DetailReturnAsset />
</template>
<script>
import DetailReturnAsset from '@/components/inventory/elements/ReturnAssetDetail/index.vue';

export default {
	name: 'DetailReturnAssetPage',
	components: { DetailReturnAsset },
};
</script>
