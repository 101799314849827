import { mapState, mapActions } from 'vuex';
import RegisterAssetMaintenance from '@/components/inventory/elements/RegisterAssetMaintenance/index.vue';
import SignerUserDialog from '@/components/inventory/elements/AddUserSignerDialog/AddUserSignerDialog.vue'
import { showError } from '@/helpers/globalHelpers';
import moment from 'moment';

export default {
    name: "DetailAssignment",
    data() {
        return {
            isLoading: false,
            userSignerDialog: false,
            //
            registerMaintenanceDialog: false,
            headers: [
                {
                    text: 'Activo',
                    align: 'start',
                    sortable: false,
                    value: 'asset',
                },
                {
                    text: 'Número de serie',
                    align: 'start',
                    sortable: false,
                    value: 'num_serie',
                },
            ],
            items: [],
            confirmationTransferDialog: false,
        };
    },
    computed: {
        breadcrumbs() {
            return [
                {
                    text: 'Inicio',
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: 'Dashboard',
                    },
                },
                {
                    text: 'Inventario',
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: 'InventoryHome',
                    },
                },
                {
                    text: 'Activos',
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: 'AssetsPermissions',
                    },
                },
                {
                    text: 'Detalle de Activo',
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: 'DetailActiveInventory',
                        params: { assetId: this.$route.params.assetId },
                    },
                },
                {
                    text: 'Detalle de Asignación',
                    link: true,
                    exact: true,
                    disabled: true,

                },
            ];
        },
        ...mapState('assetAssign', ['assetAssign']),
    },
    created() {
        this.loadAssignmentById()
    },
    methods: {
        ...mapActions('assetAssign', ['getAssetAssignmentById', 'cancelPreassignmentAsset', 'getAssetAssignCertificateById', 'cleanAssetAssign']),
        ...mapActions('assetAssignment',['getAssetAssignmentCertificateById','declinePreassignmentAsset']),

        async loadAssignmentById() {
            this.isLoading = true;

            const { assetAssignId } = this.$route.params;

            const { error } = await this.getAssetAssignmentById(assetAssignId);

            if (error) showError(error);

            this.isLoading = false;
        },

        formatDate(dateString) {
            if (!dateString) {
                return '-';
            }
            return moment(dateString).format('DD/MM/YYYY HH:mm');
        },

        setColorStatusAssignment(status) {
            switch (status) {

                case 'PREASIGNADO':
                    return {
                        background: 'light-blue lighten-5',
                        color: 'light-blue',
                        name: status,
                    }
                case 'ANULADO':
                    return {
                        background: 'red lighten-5',
                        color: 'red',
                        name: status
                    }
                case 'DECLINADO':
                    return {
                        background: '#FFF3E0',
                        color: 'orange',
                        name: status
                    }
                case 'ASIGNADO':
                    return {
                        background: 'green lighten-5',
                        color: 'green',
                        name: status
                    }
                case 'DESASIGNADO':
                    return {
                        background: 'grey lighten-3',
                        color: 'grey',
                        name: status
                    }
            }
        },

        setLastSigner(){
            const findUserSignerAditional = this.assetAssign?.asset_assignment?.assetAssignSigner?.find(signerAditional => signerAditional.status === 'PENDIENTE' && signerAditional.type === 'ASIGNACION')
            return findUserSignerAditional? findUserSignerAditional?.user_signer?.fullName:'-'
        },

        declineAssignmentAsset() {
            this.$swal({
                text: '¿Estás seguro de anular la asignación?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#0039a6',
                cancelButtonColor: 'grey',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sí, anular',
            }).then(async result => {
                if (result.isConfirmed) {
                    this.isLoading = true;
                    // const { assetAssignId } = this.$route.params;
                    const { error } = await this.declinePreassignmentAsset({ id: this.assetAssign?.asset_assignment?.id });
                    if (error) showError(error);
                    await this.loadAssignmentById()
                    this.isLoading = false;
                }
            });
        },

        registerMaintenance() {
            this.registerMaintenanceDialog = true;
        },

        getStatusColor(status) {
            switch (status) {
                case 'MOVILIZANDO':
                    return 'blue accent-4';
                case 'CANCELADO':
                    return 'red';
                case 'RECEPCIONADO':
                case 'RECEPCIONADO (*)':
                    return 'green';
                case 'RECHAZADO':
                    return 'blue-grey';
                default:
                    return ''; // color por defecto o podrías retornar algún color en particular
            }
        },

        cancelTransfer() {
            this.$swal({
                text: '¿Estás seguro de cancelar este traslado?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#0039a6',
                cancelButtonColor: 'grey',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sí, cancelar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.$router.push({ name: 'ListTransfer' });
                }
            });
        },

        declineTransfer() {
            this.$swal({
                text: '¿Estás seguro de rechazar este traslado?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#0039a6',
                cancelButtonColor: 'grey',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sí, rechazar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.$router.push({ name: 'ListTransfer' });
                }
            });
        },

        confirmTransfer() {
            this.confirmationTransferDialog = true
        },

        async downloadCertificate() {
            try {
                // const { assetAssignId } = this.$route.params;
                const { ok, response } = await this.getAssetAssignmentCertificateById({id:this.assetAssign?.asset_assignment?.id,data :Intl.DateTimeFormat().resolvedOptions().timeZone });
                if (ok) {
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'acta_entrega.pdf');
                    document.body.appendChild(link);
                    link.click();
                }
            } catch (error) {
                showError(error);
            }
        },

        addUserSigner(){
            // this.item = Object.assign({}, assetAssign);
			this.userSignerDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
        }
    
    },
    beforeDestroy() {
        this.cleanAssetAssign();
    },
    components: {
        RegisterAssetMaintenance,
        SignerUserDialog
    }
};