<template>
	<ReturnAssets />
</template>
<script>
import ReturnAssets from '@/components/inventory/elements/ReturnAssets/ReturnAssets.vue';

export default {
	name: 'ReturnAssetsPage',
	components: { ReturnAssets },
};
</script>