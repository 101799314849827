import { ratingsList } from '@/helpers/statusIncident.js';

export default {
	name: 'RatingIcons',
	props: {
		value: [String, Number],
		disabled: Boolean,
	},
	computed: {
		ratingSelect: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	data() {
		return {
			ratingsList: ratingsList,
		};
	},
	mounted() {},
	watch: {
		// value() {
		// 	this.getRating();
		// },
	},
	methods: {
		listenClick(value) {
			if (!this.disabled) {
				this.ratingSelect = value;
			}
		},
	},
};
