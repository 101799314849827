import moment from 'moment';
import { mapActions } from 'vuex';
import { showError } from "@/helpers/globalHelpers";
import AssignSpareDialog from '../../elements/AssignSpareDialog';

export default {
    name: 'AssignSpareTab',
    inject: {
        theme: {
            default: () => ({ isDark: false }),
        },
    },

    data() {
        return {
            isLoading: false,
            asset: null,
            headerSpare: [
				{
					text: 'Repuesto',
					sortable: false,
					align: 'start',
					value: 'spare.name',
				},
				{
					text: 'Número de serie',
					sortable: false,
					align: 'start',
					value: 'spare.serial_number',
				},
				{
					text: 'Fecha de asignación',
					sortable: false,
					align: 'start',
					value: 'assignment_start_date',
				},
				{
					text: 'Acciones',
					sortable: false,
					align: 'start',
					value: 'actions',
				},
			],
            assignSpareDialog: false,
        };
    },

    props: {
        value: Boolean,
    },
    
    created() {
        this.getAssetId();
    },

    computed: {
    },

    methods: {
        ...mapActions('asset', ['getAssetById', 'cleanAsset']),

		async getAssetId() {
			this.isLoading = true;
			const id = this.$route.params.assetId;
			const relations = 'assigned_spares';
			const { ok, response, error } = await this.getAssetById({ id, relations });
			if (ok) {
				this.asset = response;
			} else {
				showError(error);
			}
			this.isLoading = false;
		},

        formatDate(dateString, hour = true) {
			if (!dateString) {
				return '-';
			}
			return hour ? moment(dateString).format('DD/MM/YYYY HH:mm') : moment(dateString).format('DD/MM/YYYY')
		},

        assignSpareItem(item) {
			this.item = Object.assign({}, item);
			this.assignSpareDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

        redirectToDetail(item) {
			this.$router.push({
				name: 'DetailSpareInventory',
				params: { spareId: item.spare.id },
			});
		},
    },

    components: {
        AssignSpareDialog
    },
}