import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';

export default {
	name: 'AssignAssets',
	props: {
		userId: {
			type: [String, Number],
			required: false,
		},
		getTicketId: {
			type: [Function],
			required: false,
		},
	},
	data() {
		return {
			dialog: false,
			assignForm: { asset: null, comment: null },
			loading: false,
			listError: [],
		};
	},
	computed: {
		...mapState('asset', [
			'assets',
			'assetsPagination',
			'loadingAsset',
			'filtersAsset',
		]),
	},
	created() {
		this.getAssets();
	},
	methods: {
		//...mapActions('area', ['listAllAreas']),
		...mapActions('asset', ['listAsset', 'cleanAssets']),
		...mapActions('assetHistory', ['assignUser']),

		async getAssets() {
			this.loading = true;
			await this.listAsset(this.assetsFilters);
			this.loading = false;
		},

		async save() {
			this.listError = [];
			const data = {
				asset: this.assignForm?.asset,
				company_id: parseInt(localStorage.getItem('company_id')),
				assigned_user_id: this.userId,
				comment: this.assignForm?.comment,
			};

			const { ok, error } = await this.assignUser(data);
			if (ok) {
				this.dialog = false;
				this.getTicketId();
				$EventBus.$emit('showSnack', 'success', 'Asignación Exitosa');
			} else {
				this.listError = Array.isArray(error?.data?.message)
					? error?.data?.message
					: [error?.data?.message] || [];
				$EventBus.$emit('showSnack', 'success', this.listError);
			}
		},
	},
};
