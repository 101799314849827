<template>
	<DetailTransfer />
</template>
<script>
//import HomePermissions from '@/components/permissions/home/index.vue';
//import AssetsPermissions from '@/components/inventory/assets/index.vue';
// import AssetsPermissions from '@/components/inventory/Tabs/AssetsTab/assetsTab.vue'
import DetailTransfer from '@/components/inventory/elements/Transfer/DetailTransfer/detailTransfer.vue';

export default {
	name: 'DetailTransferPage',
	components: { DetailTransfer },
};
</script>
