import { mapActions, mapState } from "vuex";
import AssetsSelectedDialog from '@/components/inventory/assets/MultipleAssignments/components/SelectedAssetsDialog/SelectedAssetsDialog.vue';
import { showSnackBar } from "@/helpers/globalHelpers";

export default {
  name: 'CreateMultipleAssignmentsView',

  data() {
    return {
      isValidForm: null,
      isLoadingUsers: false,
      isLoadingManagements: false,
      loadingSend:false,
      selectedAssets: [],
      searchAsset: "",
      requiredRule: [(v) => !!v || "Campo requerido"],
      formAP: {
        assigned_user: null,
        service: null,
        comment: "",
        //[TODO]por confirmar
        files: [],
      },
      assetsHeader: [
        { text: "Nombre", sortable: false, value: "name" },
        { text: "AF", sortable: false, value: "af" },
        { text: "Tipo", sortable: false, value: "asset_type.name" },
        { text: "Etiqueta", sortable: false, value: "tag" },
        { text: "Acciones", sortable: false, value: "actions", align: "right" },
      ],
      fileRules: [
				(value) => {
					if (!value || value.length === 0) return true;
					return true;
				},
				(value) => {
					if (!value || value.length === 0) return true;

					const maxSizeMB = 10;
					for (const file of value) {
						const fileSizeMB = file.size / 1024 / 1024;
						if (fileSizeMB > maxSizeMB) {
							return `El archivo "${file.name}" supera el límite de tamaño de ${maxSizeMB}MB (actual: ${fileSizeMB.toFixed(2)}MB).`;
						}
					}

					return true;
				}
			]
    }
  },

  created() {
    this.loadUsers();
    this.loadManagements();
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Inicio',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'Dashboard',
          },
        },
        {
          text: 'Inventario',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'InventoryHome',
          },
        },
        {
          text: 'Activos',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "AssetsPermissions",
          },
        },
        {
          text: 'Lista de asignaciones',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "MultipleAssignmentsList",
          },
        },
        {
          text: 'Creación de asignación',
          link: true,
          exact: true,
          disabled: true,
          // to: {
          // 	name: "InventoryHome",
          // },
        },
      ];
    },
    ...mapState("security", [
      "usersActive",
      "costCenters",
      "managements"
      //  "costCenters"
    ]),
  },

  methods: {
    ...mapActions("security", [
      "getUsersActive",
      "getCostCenters",
      //  "getCostCenter"
    ]),

    ...mapActions('assetAssignment',['createAssetAssignment']),

    loadUsers: async function () {
      this.isLoadingUsers = true;
      await this.getUsersActive();
      this.isLoadingUsers = false;
    },

    loadManagements: async function () {
      this.isLoadingManagements = true;
      await this.getCostCenters();
      this.isLoadingManagements = false;
    },

    removeAsset: function (assetId) {
      const index = this.selectedAssets.findIndex(
        (e) => e?.id === assetId
      );
      if (index > -1) this.selectedAssets.splice(index, 1);
    },

    validationForm: function () {
      const formCompleted = this.$refs.form.validate();
      // const periodCompleted = this.selectedDates.length > 0;
      const assetCompleted = this.selectedAssets.length > 0;

      if (!formCompleted) {
        showSnackBar("Completar el formulario", "warning");
        return false;
      }

      // if (!periodCompleted) {
      //   showSnackBar("Completar el período", "warning");
      //   return false;
      // }

      if (!assetCompleted) {
        showSnackBar("Seleccionar los activos", "warning");
        return false;
      }

      if (formCompleted && assetCompleted ) {
        this.saveActionPlan();
      }
    },

    saveActionPlan: async function () {
      this.loadingSend = true;

      const formData = new FormData();

      formData.append(
        'assets_ids',
        JSON.stringify(this.selectedAssets.map(asset=>asset.id))
      )

      formData.append(
        'assigned_user',
        this.formAP.assigned_user
      )

      formData.append(
        'comment',
        this.formAP.comment
      )

      formData.append(
        'service',
        this.formAP.service
      )

      if(this.formAP.files && this.formAP.files.length){
        this.formAP.files.forEach((file) => {
          formData.append(
            'asset_assignment_evidences',
            file
          )
        });
      }

      // let data = {
      //   ...this.formAP,
      //   questions: [],
      //   periodicity: [],
      // };

      // this.selectedDates.forEach((date) => {
      //   data.periodicity.push({
      //     period_date: moment(date).toISOString(),
      //   });
      // });

      // this.selectedQuestions.forEach((question) => {
      //   data.questions.push(question?.id);
      // });

      const { ok, error } = await this.createAssetAssignment({ data: formData });
      if (ok) {
        this.$swal.fire({
          title: "¡La asignación fue creada con éxito!",
          // text: "En un momento te enviaremos la aprobación por correo para que puedas subir tu evidencia, gracias",
          icon: "success",
          confirmButtonColor: "#0039a6",
          confirmButtonText: "Ok",
        });
        this.$router.push({ name: "MultipleAssignmentsList",/* params: { serviceId: this.$route.params.serviceId }*/ });
      } else {
        let errorMsm = error?.data?.message;
        errorMsm = Array.isArray(errorMsm) ? errorMsm.join(" <br> ") : errorMsm;
        showSnackBar(errorMsm, "error");
      }
      this.loadingSend = false;
    },

    customFilter(item, queryText, itemText) {
			const searchTerms = queryText.toLowerCase().split(' ');
			const fullName = itemText.toLowerCase();

			return searchTerms.every(term => fullName.includes(term));
		},
  },

  beforeDestroy() {

  },

  components: {
    AssetsSelectedDialog
  }
}