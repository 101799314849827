import { mapState, mapActions } from 'vuex';
export default {
	name: 'AssignSpareDialog',
	props: {
		value: Boolean,
		item: Object,
	},
	data() {
		return {
			listError: [],
			valid: false,
			loadingSave: false,
			assignSpareForm: {
				spare: null,
				comment: '',
				files: []
			},
			assetRules: [(v) => !!v || 'El repuesto es requerido'],
			commentRules: [
				(v) => !!v || 'El comentario es requerido',
				(v) =>
					v.length <= 400 || 'El comentario debe tener menos de 400 carácteres',
			],
			fileRules: [
				(value) =>
					(value && value.length > 0) || 'Debe subir al menos un archivo.',
			],
		};
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		// ...mapState({
		// 	responsibles: (state) => state.security.usersActive,
		// }),
		...mapState('spare', ['allSpares']),

	},
	created() {
		// this.getUsersActive();
	},
	methods: {
		...mapActions('spareAssign', ['createSpareAssign']),
		// ...mapActions('security', ['getUsersActive']),
		async save() {
			if (!this.$refs?.form?.validate()) {
				return;
			}



			this.$swal({
				text: '¿Estás seguro de que deseas realizar esta acción?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#0039a6',
				cancelButtonColor: 'grey',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sí',
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.listError = [];

					const formData = new FormData();

					formData.append(
						'spare',
						this.assignSpareForm.spare
					)

					formData.append(
						'asset',
						this.item.id
					)

					formData.append(
						'comment',
						this.assignSpareForm.comment
					)

					this.assignSpareForm.files.forEach((file) => {
						formData.append(
							'spare_assign_evidences',
							file
						)
					});

					this.loadingSave = true;
					const { ok, error } = await this.createSpareAssign({ data: formData });
					if (ok) {
						this.dialog = false;
						//puede que haya un emit
						await this.$emit('getAssetById');
						this.assignSpareForm = {
							asset: '',
							comment: '',
							files: []
						},
							this.$nextTick(() => {
								if (this.$refs.form) {
									this.$refs.form.resetValidation();
								}
							});

					} else {
						this.listError = Array.isArray(error?.data?.message)
							? error?.data?.message
							: [error?.data?.message] || [];
						this.loadingSave = false;
					}
					this.loadingSave = false;
				}
			});


		},
		close() {
			this.dialog = false;
			this.assignSpareForm = {
				spare: '',
				comment: '',
				files: []
			};
			this.listError = [];
			this.$refs?.form?.resetValidation();
		},
		dialogInput(value) {
			this.$nextTick(() => {
				this.$refs.dialogForm.$el.blur();
				this.$refs.form.resetValidation();
				this.dialog = value;
				if (!value) {
					this.assignSpareForm = {
						spare: '',
						comment: '',
						files: []
					};
					this.listError = [];
				}
			})
		},
	},
};
