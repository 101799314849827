import { mapState, mapActions } from 'vuex';
import DeleteSpareDialog from '../../Spare/DeleteSparesDialog';
import CancelActiveSpareDialog from '../../Spare/CancelActiveSpareDialog';
import ReestablishSpareDialog from '../../Spare/ReestablishSpareDialog';
import AssignAssetDialog from '../../Spare/AssignAssetDialog';
import SpareUnsubscribeDialog from '../../Spare/DetailSpareUnsubscribeDialog';
import moment from 'moment';
export default {
	name: 'DetailSpare',
	data() {
		return {
			tempItemStatusChange: null,
			isLoading: false,
			panel1: [0],
			panel2: [0],
			panel3: [0],
			deleteDialog: false,
			cancelSpareDialog: false,
			reestablishSpareDialog: false,
			assignSpareDialog: false,
			spareUnsubscribeDetailDialog: false,
			item: {},
			headersHistory: [
				{
					text: 'Asignado',
					sortable: false,
					align: 'start',
					value: 'asset.name',
				},
				{
					text: 'Fecha de Registro',
					sortable: false,
					align: 'start',
					value: 'assignment_start_date',
				},
				{
					text: 'Fecha de Traspaso',
					sortable: false,
					align: 'start',
					value: 'assignment_end_date',
				},
				{
					text: 'Estado',
					sortable: false,
					value: 'status',
				},
				{
					text: 'Acciones',
					sortable: false,
					align: 'start',
					value: 'actions'
				}
			],
			headersSpareModificationHistory: [
				{
					text: 'Propiedad',
					sortable: false,
					align: 'start',
					value: 'field_changed',
				},
				{
					text: 'Valor anterior',
					sortable: false,
					align: 'start',
					value: 'old_value',
				},
				{
					text: 'Nuevo valor',
					sortable: false,
					align: 'start',
					value: 'new_value',
				},
				{
					text: 'Responsable',
					sortable: false,
					align: 'start',
					value: 'responsible.fullName',
				},
				{
					text: 'Fecha de modificación',
					sortable: false,
					align: 'start',
					value: 'created_at',
				}
			],
			headersSpareStatusHistory: [
				{
					text: 'Estado',
					sortable: false,
					align: 'start',
					value: 'status',
				},
				{
					text: 'Responsable',
					sortable: false,
					align: 'start',
					value: 'responsible.fullName',
				},
				{
					text: 'Fecha de modificación',
					sortable: false,
					align: 'start',
					value: 'created_at',
				},
			],
			tab: 0,
		};
	},
	computed: {
		breadcrumbs() {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Inventario',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InventoryHome',
					},
				},
				{
					text: 'Repuestos',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'SparesPermissions',
					},
				},
				{
					text: 'Detalle de repuesto',
					link: true,
					exact: true,
					disabled: false,
					// to: {
					// 	name: 'InventoryHome',
					// },
				},
			];
		},
		...mapState('spare', ['spare']),

		hasDeletePermission() {
			return this.$store.getters['auth/hasActivity'](
				'inventario',
				'detalleRepuesto',
				'eliminarRepuesto'
			);
		},
		hasEditPermission() {
			return this.$store.getters['auth/hasActivity'](
				'inventario',
				'editarRepuesto',
				'editarRepuesto'
			);
		},
		hasUnsubscribePermission() {
			return this.$store.getters['auth/hasActivity'](
				'inventario',
				'detalleRepuesto',
				'darDeBajaRepuesto'
			);
		},
		hasAssignPermission() {
			return this.$store.getters['auth/hasActivity'](
				'inventario',
				'detalleRepuesto',
				'asignarRepuesto'
			);
		},
	},
	created() {
		this.getSpareId();
	},
	methods: {
		...mapActions('spare', ['getSpareById', 'cleanSpare']),
		...mapActions('asset', ['listAllAssets']),
		async getSpareId() {

			this.isLoading = true;
			const id = this.$route.params.spareId;
			try {
				await this.getSpareById(id);
				await this.listAllAssets({ status: 'DISPONIBLE' });
			} catch (error) {
				console.error(error);
			} finally {
				this.isLoading = false;
			}
		},
		formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			moment.locale('es');
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},
		toggleActiveStatus(item) {
			this.tempItemStatusChange = item;
			if (item.status === 'INACTIVO') {
				this.cancelSpareItem(item);
			} else {
				this.reestablishSpare(item);
			}
		},
		handleDialogCancel(payload) {
			if (!payload.saved) {
				this.tempItemStatusChange.status =
					this.tempItemStatusChange.status === 'ACTIVO' ? 'INACTIVO' : 'ACTIVO';
			}
			this.tempItemStatusChange = null;
		},
		reestablishSpare(item) {
			this.item = Object.assign({}, item);
			this.reestablishSpareDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		cancelSpareItem(asset) {
			this.item = Object.assign({}, asset);
			this.cancelSpareDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		deleteItem(asset) {
			this.item = Object.assign({}, asset);
			this.deleteDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		detailSpareUnsubscribe(item) {
			this.item = Object.assign({}, item);
			this.spareUnsubscribeDetailDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		getSpareStatus(item) {
			return item.assignment_end_date
				? { status: 'off', color: 'red' }
				: { status: 'on', color: 'green' };
		},
		assignAssetItem(spare) {
			this.item = Object.assign({}, spare);
			this.assignSpareDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		redirectToDetail(item) {
			this.$router.push({
				name: 'DetailActiveInventory',
				params: { assetId: item.asset.id },
			});
		},
		setColorStatus(status) {
			switch (status) {
				case 'DISPONIBLE':
					return {
						background: 'green lighten-5',
						color: 'green',
						name: status
					}
				case 'ASIGNADO':
					return {
						background: '#FFF3E0',
						color: 'orange',
						name: status
					}
				case 'BAJA':
					return {
						background: 'grey lighten-3',
						color: 'grey',
						name: status
					}
			}
		},
		
		changeNameProperty(name) {
			switch (name) {
				case 'name':
					return 'NOMBRE';
				case 'purchase_value':
					return 'VALOR ADQUISICIÓN'
				case 'purchase_date':
					return 'FECHA DE COMPRA'
				case 'serial_number':
					return 'NUMERO DE SERIE';
				case 'num_asset':
					return 'NÚMERO DE ACTIVO'
				case 'tag':
					return 'ETIQUETA'
				case 'observations':
					return 'OBSERVACIONES'
				case 'maintenance_cost':
					return 'COSTO MANTENIMIENTO'
				case 'other_cost':
					return 'OTROS COSTOS'
				case 'equipment_model':
					return 'MODELO DE EQUIPO'
				case 'oc':
					return 'OC'
				case 'brand':
					return 'MARCA'
				case 'model':
					return 'MODELO'
				case 'monitor_brand':
					return 'MARCA DE MONITOR'

			}
		},
	},
	beforeDestroy() {
		this.cleanSpare();
	},
	components: {
		DeleteSpareDialog,
		CancelActiveSpareDialog,
		ReestablishSpareDialog,
		AssignAssetDialog,
		SpareUnsubscribeDialog,
	},
};
