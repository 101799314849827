import { mapState, mapActions } from 'vuex';
import DropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
	name: 'AssignAssetDialog',
	props: {
		value: Boolean,
		item: Object,
	},
	data() {
		return {
			listError: [],
			valid: false,
			loadingSave: false,
			assignSpareForm: {
				asset: null,
				comment: '',
				//
				files: null
			},
			assetRules: [(v) => !!v || 'El activo es requerido'],
			commentRules: [
				(v) => !!v || 'El comentario es requerido',
				(v) =>
					v.length <= 400 || 'El comentario debe tener menos de 400 carácteres',
			],
			fileRules: [
				(value) =>
					(value && value.length > 0) || 'Debe subir al menos un archivo.',
			],
			dropzoneOptions: {
				url: 'https://httpbin.org/post',
				thumbnailWidth: 150,
				maxFilesize: 0.5,
				headers: { 'My-Awesome-Header': 'header value' },
				dictDefaultMessage:
					'Arrastra archivos o haz clic aquí para subir imagenes',
				dictFallbackMessage:
					'Tu navegador no soporta la carga de archivos mediante arrastrar y soltar.',
				dictFileTooBig:
					'El archivo es demasiado grande ({{ fileSize }}MB). Tamaño máximo permitido: {{ maxFilesize }}MB.',
				dictInvalidFileType: 'No puedes subir archivos de este tipo.',
			},
		};
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		...mapState({
			responsibles: (state) => state.security.usersActive,
			...mapState('asset', ['allAssets']),
		}),
	},
	created() {
		this.getUsersActive();
	},
	methods: {
		// ...mapActions('spareHistory', ['assignAsset']),
		...mapActions('spareAssign', ['createSpareAssign']),
		...mapActions('security', ['getUsersActive']),
		async save() {

			if (!this.$refs?.form?.validate()) {
				return;
			}
			this.listError = [];
			const formData = new FormData();

			formData.append(
				'spare',
				this.item.id
			)

			formData.append(
				'asset',
				this.assignSpareForm.asset
			)

			formData.append(
				'comment',
				this.assignSpareForm.comment
			)

			this.assignSpareForm.files.forEach((file) => {
				formData.append(
					'spare_assign_evidences',
					file
				)
			});

			this.loadingSave = true;

			const { ok, error } = await this.createSpareAssign({
				data: formData
			});

			if (ok) {
				this.dialog = false;
				//puede que haya un emit
				await this.$emit('getSpareById');
				this.assignSpareForm = {
					asset: '',
					comment: '',
					file: null
				},
					// this.$nextTick(() => {
					// 	if (this.$refs.form) {
					// 		this.$refs.form.resetValidation();
					// 	}
					// });
					this.$refs.form.resetValidation();
			} else {
				this.listError = Array.isArray(error?.data?.message)
					? error?.data?.message
					: [error?.data?.message] || [];
			}
			this.loadingSave = false;
			// if (!this.$refs?.form?.validate()) {
			// 	return;
			// }
			// this.listError = [];
			// const data = {
			// 	...this.assignSpareForm,
			// 	company_id: parseInt(localStorage.getItem('company_id')),
			// 	spare: this.item.id,
			// };
			// this.loadingSave = true;
			// const { ok, error } = await this.createSpareAssign(data);
			// if (ok) {
			// 	this.dialog = false;
			// 	//puede que haya un emit
			// 	await this.$emit('getSpareById');
			// 	this.assignSpareForm = {
			// 		asset: '',
			// 		comment: '',
			// 		file: null
			// 	},
			// 		this.$nextTick(() => {
			// 			if (this.$refs.form) {
			// 				this.$refs.form.resetValidation();
			// 			}
			// 		});
			// } else {
			// 	this.listError = Array.isArray(error?.data?.message)
			// 		? error?.data?.message
			// 		: [error?.data?.message] || [];
			// }

			//////////

			// this.$swal({
			// 	text: '¿Estás seguro de asignar este repuesto al activo?',
			// 	icon: 'warning',
			// 	showCancelButton: true,
			// 	confirmButtonColor: '#0039a6',
			// 	cancelButtonColor: 'grey',
			// 	cancelButtonText: 'Cancelar',
			// 	confirmButtonText: 'Sí, asignarlo',
			// }).then(async (result) => {
			// 	if (result.isConfirmed) {
			// 		if (!this.$refs?.form?.validate()) {
			// 			return;
			// 		}
			// 		this.listError = [];
			// 		const data = {
			// 			...this.assignSpareForm,
			// 			company_id: parseInt(localStorage.getItem('company_id')),
			// 			spare: this.item.id,
			// 		};
			// 		this.loadingSave = true;
			// 		const { ok, error } = await this.assignAsset(data);
			// 		if (ok) {
			// 			this.dialog = false;
			// 			await this.$emit('getSpareById');
			// 			this.assignSpareForm = {
			// 				asset: '',
			// 				comment: '',
			// 			};
			// 			this.$nextTick(() => {
			// 				if (this.$refs.form) {
			// 					this.$refs.form.resetValidation();
			// 				}
			// 			});
			// 		} else {
			// 			this.listError = Array.isArray(error?.data?.message)
			// 				? error?.data?.message
			// 				: [error?.data?.message] || [];
			// 		}
			// 		this.loadingSave = false;
			// 	}
			// });
		},

		close() {
			this.dialog = false;
			this.assignSpareForm = {
				asset: '',
				comment: '',
				file: null
			};
			this.listError = [];
			this.$refs?.form?.resetValidation();
		},
		dialogInput(value) {
			this.$nextTick(() => {
				//this.$refs.button.$el.blur();
				this.$refs.form.resetValidation();
				//this.$emit('dialogClosed', { saved: false });
				this.dialog = value;
				if (!value) {
					this.assignSpareForm = {
						asset: '',
						comment: '',
						files: [],
					},
						this.listError = [];
				}
			})
		}
	},
	components: {
		DropZone
	}
};
