import ValidationAlert from "@/components/global/ValidationAlert.vue";
import moment from 'moment';
export default {
  name: "SpareUnsubscribeDialog",
  data() {
    return {
      listError: [],
      valid: false,
      loadingSave: false,
    };
  },
  props: {
    value: Boolean,
    item: Object,
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
  
    downloadFile(filePath) {
        //const filePath = "https://rentflow-public.s3.ca-central-1.amazonaws.com/1692319483365-test_image_01.png";
        const fileName = filePath.split('/').pop(); 
        let a = document.createElement('a');
        a.href = filePath;
        a.download = fileName; 
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    },
    formatDate(dateString) {
        if (!dateString) {
            return '-'; 
        }
        return moment(dateString).format('DD/MM/YYYY HH:mm');
    },
    close() {
      this.dialog = false;
    
    },
    
    dialogInput(value) {
      this.dialog = value;
    },
  },
  components: {
    ValidationAlert,
  },
};
