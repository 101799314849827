const ratingsList = [
	{ value: 1, icon: 'mdi-emoticon-angry-outline', color: 'red' },
	{ value: 2, icon: 'mdi-emoticon-sad-outline', color: 'orange' },
	{ value: 3, icon: 'mdi-emoticon-neutral-outline', color: 'yellow' },
	{ value: 4, icon: 'mdi-emoticon-happy-outline', color: 'light-green' },
	{ value: 5, icon: 'mdi-emoticon-outline', color: 'green' },
];

const incidentStatusList = [
	{ value: 'SOLICITADO', color: 'orange' },
	{ value: 'CANCELADO', color: 'red' },
	{ value: 'RESOLUCION', color: 'light-blue' },
	{ value: 'CONSULTA', color: 'yellow' },
	{ value: 'CERRADO', color: 'blue' },
	{ value: 'CALIFICADO', color: 'light-green' },
	{ value: 'PENDIENTE', color : 'skyblue'}
];

const getRating = (status = '') => {
	const find = ratingsList.find((s) => s.value == status);
	return find ? find : null;
};

const getIncidentStatus = (status = '') => {
	const find = incidentStatusList.find((s) => s.value == status);
	return find ? find : null;
};

export { ratingsList, incidentStatusList, getRating, getIncidentStatus };
