import { mapActions } from 'vuex';

export default {
	name: 'DeleteSpareDialog',
	data() {
		return {
			// dialog: false,
			listError: [],
      loadingSave: false,
		};
	},
	props: {
		value: Boolean,
		item: Object,
		source: String,
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		...mapActions('spare', ['deleteSpare']),
		close() {
			this.dialog = false;
		},
		async deleteItemConfirm() {
			if (this.item) {
        this.loadingSave = true;
				const { ok,error } = await this.deleteSpare(this.item.id);

				if (ok) {
					this.close();
					switch (this.source) {
						case 'list':
							await this.$emit('closeDeleteDialog');
							break;
						case 'detail':
							this.$router.push({ name: 'SparesPermissions' });
							break;
					}
				} else {
					this.listError = Array.isArray(error?.data?.message)
						? error?.data?.message
						: [error?.data?.message] || [];
				}
        this.loadingSave = false;
			}
		},
	},
};
