import { mapActions, mapState } from "vuex";
import { showError } from '@/helpers/globalHelpers';

export default {
    name: "AddUserSignerDialog",
    data() {
        return {
            addUserSignerForm: {
                user_signer: null,
                asset_assignment_id: null,
                description: null
            },
            responsablesRules: [(v) => !!v || 'El usuario es requerido'],
            loadingSave: false,
            listError: [],
        }
    },

    props: {
        value: Boolean,
        item: Object,
    },

    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        ...mapState({
            responsibles: (state) => state.security.usersActive,
        }),
    },

    created() {
        this.getUsersActive()
    },

    methods: {
        ...mapActions('security', ['getUsersActive']),
        ...mapActions('assetAssign', ['addSignerUserAssetAssign']),
        ...mapActions('assetAssignment',['addSignerUserAssetAssignment']),

        async addSignerUser() {
            this.loadingSave = true;
            this.addUserSignerForm.asset_assignment_id = this.item?.id;

            const { error } = await this.addSignerUserAssetAssignment({ data: this.addUserSignerForm });

            if (error) showError(error);

            await this.$emit('loadAssignmentById');

            this.addUserSignerForm = {
                user_signer: null,
                asset_assignment_id: null,
                description: null
            }

            this.loadingSave = false;
            this.dialog = false;
        },

        close() {
            this.dialog = false;
            (this.addUserSignerForm = {
                user_signer: null,
                asset_assignment_id: null,
                description: null
            }),
                (this.listError = []);
            this.$refs?.form?.resetValidation();
        },

        customFilter(item,queryText, itemText) {
			const searchTerms = queryText.toLowerCase().split(' ');
			const fullName = itemText.toLowerCase();

			return searchTerms.every(term => fullName.includes(term));
		},
    },
};
