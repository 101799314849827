<template>
	<DetailAssignment />
</template>
<script>
import DetailAssignment from '@/components/inventory/elements/DetailAssignment/index.vue';

export default {
	name: 'DetailAssignmentPage',
	components: { DetailAssignment },
};
</script>
