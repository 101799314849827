const motivesList = [
	{
		text: 'Robo',
		value: 'ROBO',
	},
	{
		text: 'Incidente',
		value: 'INCIDENTE',
	},
	{
		text: 'Inoperable',
		value: 'INOPERABLE',
	},
	{
		text: 'Otro',
		value: 'OTRO',
	},
];

const spareStatusColor = [
	{
		key: 'DISPONIBLE',
		text: 'DISPONIBLE',
		color: 'green',
		background: 'green lighten-5',
	},
	{
		key: 'ASIGNADO',
		text: 'ASIGNADO',
		color: 'orange',
		background: '#FFF3E0',
	},
	{
		key: 'BAJA',
		text: 'BAJA',
		color: 'grey',
		background: 'grey lighten-3',
	},
];

const maintenanceStatus = [
	{ key: 'INICIADO', text: 'Iniciado', color: 'blue', background: 'blue lighten-5' },
	{
		key: 'COMPLETADO',
		text: 'Completado',
		color: 'green',
		background: 'green lighten-5',
	},
];

const returnStatus = [
	{ key: 'PENDIENTE', text: 'PENDIENTE', color: 'orange', background: '#FFF3E0' },
	{
		key: 'CONFIRMADO',
		text: 'CONFIRMADO',
		color: 'blue',
		background: 'light-blue lighten-5',
	},
];

export { motivesList, spareStatusColor, maintenanceStatus, returnStatus };
