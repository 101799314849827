import { mapState, mapActions } from 'vuex';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
export default {
	name: 'RegisterLocalSpareDialog',
	props: {
		saveSpare: Function,
	},
	data() {
		return {
			dialog: false,
			listError: [],
			valid: false,
			loadingSave: false,
			sparesForm: {
				name: '',
				area: null,
				serial_number: '',
				spare_type: null,
				purchase_value: null,
				purchase_date:null
			},
			nameRules: [
				(v) => !!v || 'Nombre es requerido',
				(v) => v.length <= 50 || 'Nombre debe tener menos de 50 caracteres',
			],
			areaRules: [(v) => !!v || 'Área es requerido'],
			serieRules: [
				(v) => !!v || 'Número de serie es requerido',
				(v) =>
					v.length <= 50 || 'Número de serie debe tener menos de 50 caracteres',
			],
			typeRules: [(v) => !!v || 'Tipo de repuesto es requerido'],
			purchaseValueRules: [
				(v) => !!v || 'Valor de compra es requerido',
				(v) =>
					this.checkIntegerPart(v) ||
					'La parte entera debe tener un máximo de 6 dígitos',
				(v) =>
					/^[0-9]*(\.[0-9]{0,2})?$/.test(v) ||
					'Solo se permiten números con hasta dos decimales',
			],
			requiredRules: [(v) => !!v || 'El campo es requerido'],
		};
	},
	computed: {
		...mapState({
			companies: (state) => state.auth.user?.companies,
		}),
		...mapState({
			responsibles: (state) => state.security.usersActive,
		}),
		...mapState('area', ['allAreas']),
		...mapState('spareType', ['allSpareTypes']),
	},
	created() {
		this.listAllAreas();
		this.listAllSpareTypes();
	},
	methods: {
		...mapActions('area', ['listAllAreas', 'cleanAreas']),
		...mapActions('spareType', ['listAllSpareTypes', 'cleanSpareType']),
		...mapActions('spare', ['createSpare']),

		checkIntegerPart(value) {
			if (!value) {
				return true;
			}
			const stringValue = value.toString();
			const integerPart = stringValue.split('.')[0];
			return integerPart.length <= 6;
		},

		dialogInput(value) {
			// this.$refs.button.$el.blur();
			// this.dialog = value;
			this.$nextTick(() => {
				this.$refs.button.$el.blur();
				this.$refs.form.resetValidation();
				this.dialog = value;
				if (!value) {
					(this.sparesForm = {
						name: '',
						area: null,
						serial_number: '',
						spare_type: null,
						purchase_value: null,
						purchase_date:null
					}),
						(this.listError = []);
				}
			});
		},
		async save() {
			const result = await this.$refs.form.validate();
			if (result) {
				const data = {
					...this.sparesForm,
					purchase_value: parseFloat(this.sparesForm.purchase_value),
				};
				const isSaved = this.saveSpare(data);
				if (isSaved) {
					this.close();
				}
			}
			this.listError = [];
		},
		close() {
			this.dialog = false;
			this.sparesForm = {
				name: '',
				area: null,
				serial_number: '',
				spare_type: null,
				purchase_value: null,
				purchase_date:null
			};
			this.listError = [];
			this.$refs?.form?.resetValidation();
		},
	},
	beforeDestroy() {
		this.cleanSpareType();
		this.cleanAreas();
	},
	components: {
		ValidationAlert,
	},
};
