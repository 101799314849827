<template>
	<DevolutionAsset />
</template>
<script>
//import HomePermissions from '@/components/permissions/home/index.vue';
import DevolutionAsset from '@/components/inventory/elements/DevolutionAsset/index.vue';

export default {
	name: 'DevolutionAssetPage',
	components: { DevolutionAsset },
};
</script>
