import { mapActions, mapState } from 'vuex';
export default {
	name: 'CancelActiveDialog',
	props: {
		value: Boolean,
		item: Object,
		source: String,
	},
	data() {
		return {
			listError: [],
			valid: false,
			loadingSave: false,
			cancelActiveForm: {
				unsubscribe_comment: '',
				files: [],
				recovered_spares: []
			},
			fileRules: [
				(value) =>
					(value && value.length > 0) || 'Debe subir al menos un archivo.',
			],
			commentRules: [
				(v) => !!v || 'Nombre de activo es requerido',
				(v) => v.length <= 400 || 'El nombre debe tener menos de 400 carácteres',
			],
		};
	},
	computed: {
		...mapState('spare', ['allSpares']),
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		...mapActions('asset', ['cancelAsset']),
		async save() {
			if (!this.$refs?.form?.validate()) {
				return;
			}
			this.listError = [];
			// const data = {
			//   ...this.cancelActiveForm,
			//   company_id: parseInt(localStorage.getItem("company_id")),
			// };
			const formData = new FormData();
			const company_id = parseInt(localStorage.getItem('company_id'));
			formData.append('company_id', company_id);
			formData.append('recovered_spares', JSON.stringify(this.cancelActiveForm.recovered_spares))

			formData.append(
				'unsubscribe_comment',
				this.cancelActiveForm.unsubscribe_comment
			);

			this.cancelActiveForm.files.forEach((file) => {
				formData.append('files', file);
			});

			this.loadingSave = true;

			const { ok, error } = await this.cancelAsset({
				id: this.item.id,
				data: formData,
			});
			if (ok) {
				this.dialog = false;
				//puede que haya un emit
				//this.$emit('dialogClosed', { saved: true });
				switch (this.source) {
					case 'list':
						await this.$emit('getAssets');
						await this.$emit('closeCreateDialog');
						break;
					case 'detail':
						await this.$emit('getAssetById');
						break;
				}
				//
				this.cancelActiveForm = {
					unsubscribe_comment: '',
					files: [],
					recovered_spares: []
				};
				this.$refs.form?.resetValidation();
			} else {
				this.listError = Array.isArray(error?.data?.message)
					? error?.data?.message
					: [error?.data?.message] || [];
			}
		},
		close() {
			this.dialog = false;
			//emit
			//this.$emit('dialogClosed', { saved: false });
			//
			this.cancelActiveForm = {
				unsubscribe_comment: '',
				files: [],
				recovered_spares: []
			};
			this.listError = [];
			this.$refs?.form?.resetValidation();
		},

		dialogInput(value) {
			this.$nextTick(() => {
				//this.$refs.button.$el.blur();
				this.$refs.form.resetValidation();
				//this.$emit('dialogClosed', { saved: false });
				this.dialog = value;
				if (!value) {
					this.cancelActiveForm = {
						unsubscribe_comment: '',
						files: [],
						recovered_spares: []
					};
					this.listError = [];
				}
			})
		}
	},
};
