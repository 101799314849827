<template>
	<AssignUserAssets />
</template>
<script>
import AssignUserAssets from '@/components/inventory/elements/AssignUserAssets/AssignUserAssets.vue';

export default {
	name: 'AssignUserAssetsPage',
	components: { AssignUserAssets },
};
</script>