import { mapActions } from 'vuex';
import RegisterSpareDialog from '@/components/inventory/elements/RegisterLocalSpareDialog/index.vue';
import { showError } from '@/helpers/globalHelpers';
import { motivesList } from '@/helpers/globalValues.js';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import DragDropFile from '@/components/global/DragDropFile.vue';
import { $EventBus } from '@/main';

export default {
	name: 'AssetUnsubscribeForm',
	data() {
		return {
			asset: null,
			listError: [],
			valid: false,
			isLoading: false,
			headers: [
				{
					text: 'Repuesto',
					sortable: false,
					align: 'start',
					value: 'name',
				},
				{
					text: 'Número de serie',
					sortable: false,
					align: 'start',
					value: 'serial_number',
				},
				{
					text: 'Acciones',
					sortable: false,
					align: 'start',
					value: 'actions',
				},
			],
			items: [],
			registerSpareDialog: false,
			motivesList: motivesList,
			recoveredSpares: [],
			unsubscribeForm: {
				reason: null,
				comment: null,
				evidences: [],
				recovered_spares: null,
			},
			currentSpare: null,
			loadingSend: false,
		};
	},
	computed: {
		breadcrumbs() {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Inventario',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InventoryHome',
					},
				},
				{
					text: 'Activos',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'AssetsPermissions',
					},
				},
				{
					text: 'Detalle de activo',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'DetailActiveInventory',
					},
				},
				{
					text: 'Baja  de activo',
					link: true,
					exact: true,
					disabled: true,
					// to: {
					// 	name: 'InventoryHome',
					// },
				},
			];
		},

		assetId() {
			return this.$route?.params?.assetId || null;
		},

		assignedSpares() {
			const assignments = this.asset?.assigned_spares;

			if (Array.isArray(assignments)) {
				let result = [];
				assignments.forEach((element) => {
					const { id, name, purchase_value, area, serial_number, spare_type, purchase_date } =
						element?.spare;

					const isRepeated = this.recoveredSpares.some((e) => e?.id == id);
					if (!isRepeated) {
						result.push({
							id,
							name,
							purchase_value,
							purchase_date,
							serial_number,
							area: area?.id,
							spare_type: spare_type?.id,
						});
					}
				});

				return result;
			}
			return [];
		},
	},

	created() {
		this.loadAsset();
	},

	methods: {
		...mapActions('asset', ['getAssetById', 'cancelAsset', 'cleanAsset']),

		async loadAsset() {
			const relations = 'assigned_spares'
			const { ok, response, error } = await this.getAssetById({ id: this.assetId, relations });
			if (ok) {
				this.asset = response;

				if (this.asset?.status === 'BAJA') {
					this.$swal({
						text: 'No se puede dar de baja a un Activo en estado: BAJA',
						icon: 'warning',
						confirmButtonColor: '#0039a6',
					});
					this.$router.push({
						name: 'DetailActiveInventory',
						params: { assetId: this.asset?.id },
					});
				}
			} else {
				showError(error);
			}
		},

		listenChange(value) {
			if (value) {
				this.addRecoveredSpares(value);
				this.$refs.spareAutocomplete.clearableCallback();
			}
		},

		addRecoveredSpares(spare) {
			const repeatedSeries = this.recoveredSpares.some(
				(e) => e.serial_number == spare?.serial_number
			);

			if (repeatedSeries) {
				$EventBus.$emit(
					'showSnack',
					'warning',
					'La serie que has ingresado ya existe'
				);
				return false;
			}

			this.recoveredSpares.push(spare);
			return true;
		},

		removeItem(index) {
			this.recoveredSpares.splice(index, 1);
		},

		async sendUnsubscribe() {

			this.loadingSend = true;
			this.listError = [];
			let formData = new FormData();
			formData.append('reason', this.unsubscribeForm?.reason);
			formData.append('comment', this.unsubscribeForm?.comment);
			formData.append('recovered_spares', JSON.stringify(this.recoveredSpares));

			if (Array.isArray(this.unsubscribeForm?.evidences)) {
				this.unsubscribeForm?.evidences.forEach((file) => {
					formData.append('asset_unsubscribe_evidences', file, file?.name);
				});
			}

			const { ok, error } = await this.cancelAsset({
				id: this.asset?.id,
				data: formData,
			});

			if (ok) {
				this.$swal({
					text: '¡Operación completada con éxito!',
					icon: 'success',
					confirmButtonColor: '#0039a6',
				});
				this.$router.push({
					name: 'DetailActiveInventory',
					params: { assetId: this.asset?.id },
				});
			} else {
				const msm = error?.data?.message;
				this.listError = Array.isArray(msm) ? msm : [msm];
			}
			this.loadingSend = false;
		},

		async save() {
			this.$swal({
				text: '¿Está seguro de dar de baja este activo?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#0039a6',
				cancelButtonColor: 'grey',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sí, dar de baja',
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.sendUnsubscribe();
				}
			});
		},
	},

	beforeDestroy() {
		this.cleanAsset();
	},

	components: {
		RegisterSpareDialog,
		ValidationAlert,
		DragDropFile,
	},
};
