export default {
    name: 'ConfirmationTransferDialog',
    data() {
        return {
            headers: [
                {
                    text: 'Activo',
                    align: 'start',
                    sortable: false,
                    value: 'asset',
                },
                {
                    text: 'Número de serie',
                    align: 'start',
                    sortable: false,
                    value: 'num_serie',
                },
                {
                    text: 'Comentario',
                    align: 'start',
                    sortable: false,
                    value: 'comment',
                },
                {
                    text: 'Evidencias',
                    align: 'start',
                    sortable: false,
                    value: 'evidence',
                },
            ],
            items: [],
        };
    },
    props: {
        value: Boolean,
        // item: Object,
        // //
        // returnAsset: Boolean
    },
    created() {
        this.loadDataDummy()
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        loadDataDummy() {
            this.items = [
                {
                    asset: 'Iphone',
                    num_serie: 'abc123',
                    comment:'Comment 01',
                    evidence:'Evidence 01',
                    id: 1
                },
                {
                    asset: 'Iphone',
                    num_serie: 'abc123',
                    comment:'Comment 01',
                    evidence:'Evidence 01',
                    id: 2
                },
                {
                    asset: 'Iphone',
                    num_serie: 'abc123',
                    comment:'Comment 01',
                    evidence:'Evidence 01',
                    id: 3
                },
                {
                    asset: 'Iphone',
                    num_serie: 'abc123',
                    comment:'Comment 01',
                    evidence:'Evidence 01',
                    id: 4
                },
                {
                    asset: 'Iphone',
                    num_serie: 'abc123',
                    comment:'Comment 01',
                    evidence:'Evidence 01',
                    id: 5
                }
            ]
        },
    }
}