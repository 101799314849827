import { mapState, mapActions } from 'vuex';
import DropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    name: 'RegisterAssetTransfer',
    props: {
        value: Boolean,
        item: Object,
        //
        releaseAsset: Boolean,
    },
    data() {
        return {
            listError: [],
            valid: false,
            loadingSave: false,
            transferAssetForm: {
                asset: null,
                // origin_service: null,
                destination_service: null,
                comment: '',
            },
            requiredRules: [(v) => !!v || 'El campo es requerido'],
            commentRules: [
                (v) => !!v || 'Comentario es requerido',
                (v) =>
                    v.length <= 400 || 'Comentario debe tener menos de 400 caracteres',
            ],
            servicesDataDummy: [
                { id: 1, text: 'MARSA' },
                { id: 2, text: 'YANACOCHA' },
                { id: 3, text: 'COLCA' },
            ],
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                headers: { 'My-Awesome-Header': 'header value' },
                dictDefaultMessage: 'Arrastra archivos o haz clic aquí para subir evidencias',
                dictFallbackMessage: 'Tu navegador no soporta la carga de archivos mediante arrastrar y soltar.',
                dictFileTooBig: 'El archivo es demasiado grande ({{ fileSize }}MB). Tamaño máximo permitido: {{ maxFilesize }}MB.',
                dictInvalidFileType: 'No puedes subir archivos de este tipo.',
            },
        };
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        // ...mapState({
        //     responsibles: (state) => state.security.usersActive,
        // }),
        ...mapState('security', ['costCenters', 'managements']),
    },
    created() {
        this.getUsersActive();
    },
    methods: {
        // ...mapActions('assetHistory', ['assignUser']),
        // ...mapActions('security', ['getUsersActive']),
        ...mapActions('security', ['getUsersActive']),
        ...mapActions('assetTransfer', ['createAssetTransfer']),
        async save() {

            if (!this.$refs?.form?.validate()) {
                return;
            }
            // if (!this.$refs?.form?.validate()) {
            //   return;
            // }
            // this.listError = [];
            // const data = {
            //   ...this.assignUserForm,
            //   company_id: parseInt(localStorage.getItem("company_id")),
            //   asset: this.item.id,
            // };
            // this.loadingSave = true;
            // const { ok, error } = await this.assignUser(data);
            // if (ok) {
            //   this.dialog = false;
            //   //puede que haya un emit
            //   await this.$emit("getAssetId");
            //   this.assignUserForm = {
            //     asset: "",
            //     assigned_user_id: "",
            //     comment: "",
            //   };
            //   this.$nextTick(() => {
            //     if (this.$refs.form) {
            //       this.$refs.form.resetValidation();
            //     }
            //   });
            // } else {
            //   this.listError = Array.isArray(error?.data?.message)
            //     ? error?.data?.message
            //     : [error?.data?.message] || [];
            // }
            // Mostrar SweetAlert para confirmación antes de asignar el usuario.
            this.$swal({
                text: '¿Estás seguro de que deseas realizar esta acción?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#0039a6',
                cancelButtonColor: 'grey',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sí',
            }).then(async (result) => {
                if (result.isConfirmed) {


                    this.listError = [];

                    this.loadingSave = true;
                    const data = {
                        ...this.transferAssetForm,
                        asset: this.item.id
                    }
                    const { ok, error } = await this.createAssetTransfer({ data: data });
                    if (ok) {
                        this.dialog = false;
                        this.$emit('transfer-registered');
                        this.transferAssetForm = {
                            asset: '',
                            //origin_service: '',
                            destination_service: '',
                            comment: '',
                        };
                    } else {
                        this.listError = Array.isArray(error?.data?.message)
                            ? error?.data?.message
                            : [error?.data?.message] || [];
                    }
                    this.loadingSave = false;
                }
            });
        },
        close() {
            this.dialog = false;
            (this.transferAssetForm = {
                asset: '',
                //origin_service: '',
                destination_service: '',
                comment: '',
            }),
                (this.listError = []);
            this.$refs?.form?.resetValidation();
        },
        dialogInput(value) {
            this.$nextTick(() => {
                this.$refs.dialogForm.$el.blur();
                this.$refs.form.resetValidation();
                this.dialog = value;
                if (!value) {
                    this.transferAssetForm = {
                        asset: '',
                        //origin_service: '',
                        destination_service: '',
                        comment: '',
                    }
                    this.listError = [];
                }
            })
        },
    },
    components: {
        DropZone,
    },
};
