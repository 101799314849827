import { mapActions, mapState } from 'vuex';
import { showError } from '@/helpers/globalHelpers';
import moment from 'moment';

export default {
  name: 'ListMultipleAssignmentsView',

  data() {
    return {
      loading: false,
      tab: 0,
      isLoadingUsers: false,
      isLoadingManagements: false,
      headers: [
        { text: 'Responsable', sortable: false, value: 'responsible.fullName' },
        { text: 'Usuario asignado', sortable: false, value: 'assigned_user.fullName' },
        { text: 'Fecha de preasignación', sortable: false, value: 'preassignment_date' },
        { text: 'Servicio', sortable: false, value: 'service.name' },
        { text: 'Estado', sortable: false, value: 'status' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      assetAssignmentFilters: {
        page: 1,
        limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
        pagination: true,
        status: null,
        //
        responsible: null,
        assigned_user: null,
        service: null,
      },
      status: [
        { name: 'PREASIGNADO' },
        { name: 'ANULADO' },
        { name: 'DECLINADO' },
        { name: 'ASIGNADO' },
        { name: 'DESASIGNADO' },
        { name: 'DESASIGNADO_BAJA' },
      ],
      // Asset assignment return
      assetAssignmentReturnFilters: {
        page: 1,
        limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
        pagination: true,
        //
        receptor_user: null,
        status: null,
        service: null,
        user_assigned: null,
        reason: null,
      },
      reason: [
        { name: 'CESE DE SERVICIO' },
        { name: 'DESPIDO' },
        { name: 'VACACIONES' },
      ],
      statusAssetAssignmentReturn: [
        { name: 'PENDIENTE' },
        { name: 'CONFIRMADO' }
      ],
      headersAssignmentReturn: [
        { text: 'Razón', sortable: false, value: 'reason' },
        { text: 'Responsable', sortable: false, value: 'asset_assignment.assigned_user.fullName' },
        { text: 'Usuario receptor', sortable: false, value: 'receptor_user.fullName' },
        { text: 'Servicio', sortable: false, value: 'service.name' },
        { text: 'Estado', sortable: false, value: 'status' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ]
    }
  },

  created() {
    this.getAssetAssignment();
    this.getAssetAssignmentReturn();
    this.loadUsers();
    this.loadManagements();
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Inicio',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'Dashboard',
          },
        },
        {
          text: 'Inventario',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'InventoryHome',
          },
        },
        {
          text: 'Activos',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "AssetsPermissions",
          },
        },
        {
          text: 'Lista de asignaciones',
          link: true,
          exact: true,
          disabled: true,
          // to: {
          // 	name: "InventoryHome",
          // },
        },
      ];
    },
    ...mapState('assetAssignment', [
      'allAssetsAssignment',
      'assetAssignmentPagination',
      'allassetsAssignmentReturn',
      'assetAssignmentReturnPagination',
    ]),
    ...mapState("security", [
      "usersActive",
      "costCenters",
      "managements",
    ]),
    setTitle() {
      return this.tab < 1 ? 'Lista de asignaciones' : 'Lista de devoluciones'
    }
  },

  methods: {
    ...mapActions('assetAssignment', ['listPaginationAssetAssignment', 'listPaginationAssetAssignmentReturn']),
    ...mapActions("security", [
      "getUsersActive",
      "getCostCenters",
    ]),

    loadUsers: async function () {
      this.isLoadingUsers = true;
      await this.getUsersActive();
      this.isLoadingUsers = false;
    },

    loadManagements: async function () {
      this.isLoadingManagements = true;
      await this.getCostCenters();
      this.isLoadingManagements = false;
    },

    async getAssetAssignment() {
      this.loading = true;
      const { error } = await this.listPaginationAssetAssignment(this.assetAssignmentFilters);
      if (error) showError(error);
      this.loading = false;
    },

    async getAssetAssignmentReturn() {
      this.loading = true;
      const { error } = await this.listPaginationAssetAssignmentReturn(this.assetAssignmentReturnFilters);
      if (error) showError(error);
      this.loading = false;
    },

    setColorStatusAssignment(status) {
      switch (status) {
        case 'PREASIGNADO':
          return {
            background: 'light-blue lighten-5',
            color: 'light-blue',
            name: status,
          };
        case 'ANULADO':
          return {
            background: 'red lighten-5',
            color: 'red',
            name: status,
          };
        case 'DECLINADO':
          return {
            background: '#FFF3E0',
            color: 'orange',
            name: status,
          };
        case 'ASIGNADO':
          return {
            background: 'green lighten-5',
            color: 'green',
            name: status,
          };
        case 'DESASIGNADO':
          return {
            background: 'grey lighten-3',
            color: 'grey',
            name: status,
          };
        case 'DESASIGNADO_BAJA':
          return {
            background: 'grey lighten-4',
            color: 'grey',
            name: 'DESASIGNADO-BAJA',
          };

      }
    },

    setColorStatusAssignmentReturn(status) {
      switch (status) {
        case 'PENDIENTE':
          return {
            background: 'light-blue lighten-5',
            color: 'light-blue',
            name: status,
          };
        case 'CONFIRMADO':
          return {
            background: 'green lighten-5',
            color: 'green',
            name: status,
          };
      }
    },

    formatDate(dateString) {
      if (!dateString) {
        return '-';
      }
      return moment(dateString).format('DD/MM/YYYY HH:mm');
    },
  },

  beforeDestroy() {

  },

  components: {

  }
}