import { mapActions } from 'vuex';
import RegisterAssetMaintenance from '@/components/inventory/elements/RegisterAssetMaintenance/index.vue';
import moment from 'moment';
import { showError } from "@/helpers/globalHelpers";

export default {
    name: 'MaintenanceTable',
    inject: {
		theme: {
			default: () => ({ isDark: false }),
		},
	},
    data() {
        return {
            isLoading: false,
            asset:null,
            registerMaintenanceDialog: false,
            headers: [
                {
                    text: 'Fecha de Inicio',
                    align: 'start',
                    sortable: false,
                    value: 'maintenance_start_date',
                },
                {
                    text: 'Fecha de culminación',
                    align: 'start',
                    sortable: false,
                    value: 'maintenance_end_date',
                },
                {
                    text: 'Costo de mantenimiento',
                    align: 'start',
                    sortable: false,
                    value: 'maintenance_cost',
                },
                {
                    text: 'Tipo',
                    sortable: false,
                    value: 'type',
                },
                {
                    text: 'Registrado por',
                    align: 'start',
                    sortable: false,
                    value: 'responsible.fullName',
                },
                {
                    text: 'Estado',
                    align: 'start',
                    sortable: false,
                    value: 'status',
                },
                {
                    text: 'Acciones',
                    sortable: false,
                    value: 'actions',
                },
            ],
        };
    },

    props: {
        value: Boolean,
    },


    computed: { },

    created() {
        this.getAssetId();
    },

    methods: {
        ...mapActions('asset', ['getAssetById']),

        async getAssetId() {
            this.isLoading = true;
            const id = this.$route.params.assetId;
            const relations = 'maintenances';
            const { ok, response, error } = await this.getAssetById({ id, relations });
            if (ok) {
				this.asset = response;
			} else {
				showError(error);
			}
			this.isLoading = false;
        },

        getStatusColor(status) {
            switch (status) {
                case 'INICIADO':
                    return { background: 'blue lighten-4', color: 'blue' };
                case 'COMPLETADO':
                    return { background: 'green lighten-5', color: 'green' };
                default:
                    return '';
            }
        },

        registerMaintenance() {
            this.registerMaintenanceDialog = true;
        },

        formatDate(dateString) {
            if (!dateString) {
                return '-';
            }
            return moment(dateString).format('DD/MM/YYYY HH:mm');
        },
    },

    components: {
        RegisterAssetMaintenance
    },
};
