import { mapState, mapActions } from 'vuex';
import TransferConfirmationDialog from '../../../elements/Transfer/ConfirmationTransferDialog'
import moment from 'moment';
import { showError } from '@/helpers/globalHelpers';
export default {
    name: "ListReception",
    data() {
        return {
            isLoading: false,
            headers: [
                {
                    text: 'Activo',
                    align: 'start',
                    sortable: false,
                    value: 'asset',
                },
                {
                    text: 'Número de serie',
                    align: 'start',
                    sortable: false,
                    value: 'num_serie',
                },
            ],
            items: [],
            confirmationTransferDialog: false,
        };
    },
    computed: {
        breadcrumbs() {
            return [
                {
                    text: "Inicio",
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: "Dashboard",
                    },
                },
                {
                    text: "Inventario",
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: 'AssetsPermissions',
                    },
                },
                {
                    text: 'Detalle de Activo',
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: 'DetailActiveInventory',
                        params: { assetId: this.$route.params.assetId },
                    },
                },
                {
                    text: 'Detalle de Traslado',
                    link: true,
                    exact: true,
                    disabled: true,
                    // to: {
                    // 	name: '/',
                    // },
                },
            ];
        },
        ...mapState('assetTransfer', ['assetTransfer']),
    },
    created() {

        this.loadAssetTransferById();
    },
    methods: {
        ...mapActions('assetTransfer', ['getSpareAssingById', 'cleanAssetsTransfer']),
        formatDate(dateString) {
            if (!dateString) {
                return '-';
            }
            return moment(dateString).format('DD/MM/YYYY HH:mm');
        },

        async loadAssetTransferById() {
            this.isLoading = true;

            const { transferId } = this.$route.params;

            const { error } = await this.getSpareAssingById(transferId);

            if (error) showError(error);
            this.isLoading = false;
        },

        loadDataDummy() {
            this.items = [
                {
                    asset: 'Iphone',
                    num_serie: 'abc123',

                    id: 1
                },
                {
                    asset: 'Iphone',
                    num_serie: 'abc123',

                    id: 2
                },
                {
                    asset: 'Iphone',
                    num_serie: 'abc123',

                    id: 3
                },
                {
                    asset: 'Iphone',
                    num_serie: 'abc123',

                    id: 4
                },
                {
                    asset: 'Iphone',
                    num_serie: 'abc123',

                    id: 5
                }
            ]
        },
        getStatusColor(status) {
            switch (status) {
                case 'MOVILIZANDO':
                    return 'blue accent-4';
                case 'CANCELADO':
                    return 'red';
                case 'RECEPCIONADO':
                case 'RECEPCIONADO (*)':
                    return 'green';
                case 'RECHAZADO':
                    return 'blue-grey';
                default:
                    return ''; // color por defecto o podrías retornar algún color en particular
            }
        },
        cancelTransfer() {
            this.$swal({
                text: '¿Estás seguro de cancelar este traslado?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#0039a6',
                cancelButtonColor: 'grey',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sí, cancelar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.$router.push({ name: 'ListTransfer' });
                }
            });
        },
        declineTransfer() {
            this.$swal({
                text: '¿Estás seguro de rechazar este traslado?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#0039a6',
                cancelButtonColor: 'grey',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sí, rechazar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.$router.push({ name: 'ListTransfer' });
                }
            });
        },
        confirmTransfer() {
            this.confirmationTransferDialog = true;
        },

    },
    beforeDestroy() {
        this.cleanAssetsTransfer();
    },
    components: {
        TransferConfirmationDialog
    }
};