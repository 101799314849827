import { mapActions, mapState } from "vuex";
export default {
  name: 'SelectedAssetsDialog',

  data() {
    return {
      dialog: false,
      loadingAssets: false,
      searchAsset: null,
      searchTimeout: null,
      assetsHeader: [
        { text: "Nombre", sortable: false, value: "name" },
        { text: "AF", sortable: false, value: "af" },
        { text: "Tipo", sortable: false, value: "asset_type.name" },
        { text: "Etiqueta", sortable: false, value: "tag" },
        { text: "Acciones", sortable: false, value: "actions", align: "right" },
      ],
      filters: {
        name: null,
        status: 'DISPONIBLE'
        // service_id: null,
      },
    }
  },

  props: {
    value: Array,
    // serviceId: Number
  },

  computed: {
    ...mapState("asset", ["allAssets"]),

    selectedAssets: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  created() {
  },

  watch: {
    dialog(newValue) {
      if (newValue) {
        this.filters = {
          name: null,
          status: 'DISPONIBLE'
        };
        this.getAssets();
      }
    },
  },

  methods: {
    ...mapActions("asset", ["listAllAssets"]),

    getAssets: async function () {

      this.loadingAssets = true;
      // if (this.serviceId) {
      //   this.filters.service_id = this.serviceId;
      // }

      await this.listAllAssets(this.filters);
      this.loadingAssets = false;
    },

    addAsset: function (asset) {
      this.selectedAssets.push(asset);
    },

    removeAsset: function (assetId) {
      const index = this.selectedAssets.findIndex(
        (e) => e?.id === assetId
      );
      if (index > -1) this.selectedAssets.splice(index, 1);
    },

    isAssetSelected: function (assetId) {
      return this.selectedAssets.some((e) => e?.id == assetId);
    },

    debounceSearch() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.filters.name = this.searchAsset;
        this.getAssets();
      }, 800);
    },

    close() {
      this.dialog = false;
    },
  },

  beforeDestroy() {

  },

  components: {

  }
}