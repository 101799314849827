<template>
	<DetailActive />
</template>
<script>
//import HomePermissions from '@/components/permissions/home/index.vue';
import DetailActive from '@/components/inventory/elements/DetailActive/index.vue';

export default {
	name: 'DetailActivePage',
	components: { DetailActive },
};
</script>
