export default {
	name: 'TicketDetailElement',
	data() {
		return {};
	},

	props: {
		icon: String,
		label: String,
	},

	computed: {},

	created() {},

	methods: {},
};
