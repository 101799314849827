<template>
	<IncidentConfirm />
</template>
<script>
//import HomePermissions from '@/components/permissions/home/index.vue';
import IncidentConfirm from '@/components/incidents/IncidentConfirm/IncidentConfirm.vue';

export default {
	name: 'IncidentConfirmPage',
	components: { IncidentConfirm },
};
</script>
