var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"px-0 pt-2 pb-0",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),_c('v-container',[_c('v-card',{attrs:{"flat":"","loading":_vm.isLoading}},[_c('v-card-title',[_vm._v("Detalle de Devolución")]),(_vm.assetAssign)?_c('v-container',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('table',[_c('tbody',[_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v("Motivo:")]),_c('td',{staticClass:"pa-1"},[_vm._v(" "+_vm._s(_vm.assetAssign?.return?.asset_assignment_return?.reason)+" ")])]),_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v(" Estado de activo: ")]),_c('td',{staticClass:"pa-1"},[_vm._v(" "+_vm._s(_vm.assetAssign?.return?.asset_status)+" ")])]),_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v(" Comentario: ")]),_c('td',{staticClass:"pa-1"},[_vm._v(" "+_vm._s(_vm.assetAssign?.return?.asset_assignment_return?.comment)+" ")])]),_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v("Servicio:")]),_c('td',{staticClass:"pa-1"},[(_vm.assetAssign?.return)?_c('div',[_vm._v(" "+_vm._s(_vm.assetAssign?.return?.asset_assignment_return?.service ?.costCenter)+" - "+_vm._s(_vm.assetAssign?.return?.asset_assignment_return?.service?.name)+" ")]):_vm._e()])]),_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v(" Evidencias: ")]),_c('td',[_c('div',_vm._l((_vm.assetAssign?.return
													?.evidence_files),function(evidence){return _c('v-chip',{key:evidence.id,staticClass:"ma-2",attrs:{"href":evidence.evidence_file,"color":"primary","target":"_blank","outlined":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-link ")]),_vm._v(" Archivo ")],1)}),1)])]),_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v("Estado:")]),_c('td',{staticClass:"pa-1"},[(
												_vm.getStateProperties(
													_vm.assetAssign.return?.asset_assignment_return?.status
												)
											)?_c('v-chip',{attrs:{"small":"","color":_vm.getStateProperties(
													_vm.assetAssign.return?.asset_assignment_return?.status
												)?.background,"text-color":_vm.getStateProperties(
													_vm.assetAssign.return?.asset_assignment_return?.status
												)?.color}},[_vm._v(" "+_vm._s(_vm.getStateProperties( _vm.assetAssign.return?.asset_assignment_return?.status ).text)+" ")]):_vm._e()],1)])])])]),_c('v-col',{staticClass:"d-flex flex-column justify-end align-end",attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"text-center mb-2",attrs:{"color":"primary","width":"200","disabled":_vm.assetAssign?.return.status !== 'CONFIRMADO'},on:{"click":_vm.downloadCertificateAssetReturn}},[_vm._v("Ver Constancia")])],1)],1)],1):_vm._e()],1),_c('register-asset-maintenance',{attrs:{"releaseAsset":true},model:{value:(_vm.registerMaintenanceDialog),callback:function ($$v) {_vm.registerMaintenanceDialog=$$v},expression:"registerMaintenanceDialog"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }