import { mapActions, } from "vuex";
import { showError } from "@/helpers/globalHelpers";
export default {
  name: 'DetailAssignmentReturnDialog',

  components: {

  },

  props: {
    value: Boolean,
    item: Object,
    setLastSignerReturn: String
  },

  data() {
    return {
      dialog: false,
      loadingDownloadCertificateAssignment: false,
      headersAssetAssignmentReturn: [
        { text: 'Nombre de activo', sortable: false, value: 'asset_assign.asset.name' },
        { text: "Número de serie", sortable: false, value: "asset_assign.asset.num_asset" },
        // { text: "Tipo", sortable: false, value: "asset_assign.asset.name" },
        // { text: "Área", sortable: false, value: "asset_assign.asset.area.name" },
        { text: 'Estado de devolución  activo', sortable: false, value: 'asset_status' }
      ]
    }
  },

  computed: {
    isConfirmed() {
      return this.item.status !== 'CONFIRMADO'
    }
  },

  created() {

  },

  mounted() {

  },

  beforeDestroy() {

  },

  methods: {
    ...mapActions('assetAssignment', ['getAssetAssignmentReturnCertificate']),

    async downloadCertificate() {
      try {
        this.loadingDownloadCertificateAssignment = true;
        const { ok, response } = await this.getAssetAssignmentReturnCertificate({ id: this.item.id, data: Intl.DateTimeFormat().resolvedOptions().timeZone });
        if (ok) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'acta_devolución.pdf');
          document.body.appendChild(link);
          link.click();
        }
        this.loadingDownloadCertificateAssignment = false;
      } catch (error) {
        showError(error);
      }
    },

    setColorStatusAssignmentReturn(status) {
      switch (status) {
        case 'PENDIENTE':
          return {
            background: 'light-blue lighten-5',
            color: 'light-blue',
            name: status,
          };

        case 'CONFIRMADO':
          return {
            background: 'green lighten-5',
            color: 'green',
            name: status,
          };
      }
    },

    close() {
      this.dialog = false;
    },
  }
}