var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"px-0 pt-2 pb-0",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),(_vm.isLoading)?_c('v-layout',{attrs:{"fill-height":"","justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('v-container',[_c('v-card',{attrs:{"flat":""}},[(!_vm.isLoading)?_c('div',[_c('v-container',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Asignación de Activos ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-autocomplete',{attrs:{"dense":"","solo":"","placeholder":"Seleccionar servicio","items":[
									'YANACOCHA',
									'SERVICIO A',
									'SERVICIO B',
									'SERVICIO C',
								]}}),_c('v-autocomplete',{attrs:{"dense":"","solo":"","placeholder":"Seleccionar usuario","items":['Hugo', 'Paco', 'Luis']}}),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',[_c('v-autocomplete',{attrs:{"dense":"","solo":"","placeholder":"Buscar activo","items":[
											'iPhone 15 Pro Max',
											'Laptop HP 15 Pavilion',
										]}})],1),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Agregar activo ")],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"bordered-div"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:`item.actions`,fn:function({}){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:`item.comment`,fn:function({}){return [_c('v-text-field',{attrs:{"label":"Máx. 30 caracteres"}})]}},{key:`item.evidences`,fn:function({}){return [_c('v-file-input',{attrs:{"multiple":"","show-size":"","clearable":""}})]}}],null,true)})],1)]),_c('v-col',{attrs:{"cols":"12","md":"1"}}),_c('v-col',{staticClass:"d-flex justify-end align-end",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"grey","outlined":""}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(" Asignar activo ")])],1)])],1)],1)],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }