<template>
	<ListMultipleAssignments />
</template>
<script>
import ListMultipleAssignments from '@/components/inventory/assets/MultipleAssignments/ListMultipleAssignments/ListMultipleAssignments.vue';

export default {
	name: 'ListMultipleAssignmentsPage',
	components: { ListMultipleAssignments },
};
</script>
