<template>
	<DetailMultipleAssignments />
</template>
<script>
import DetailMultipleAssignments from '@/components/inventory/assets/MultipleAssignments/DetailMultipleAssignments/DetailMultipleAssignments.vue';

export default {
	name: 'DetailMultipleAssignmentsPage',
	components: { DetailMultipleAssignments },
};
</script>
