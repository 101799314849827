import { mapActions } from 'vuex';
import { $EventBus } from '@/main';
import { ratingsList } from '@/helpers/statusIncident.js';
import RatingIcons from '@/components/request/elements/RatingIcons/RatingIcons.vue';

export default {
	name: 'RatingDialog',
	props: {
		value: Boolean,
		ticketId: [String, Number],
	},
	computed: {
		dialog: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	data() {
		return {
			loading: false,
			loadingSave: false,
			ratingsList: ratingsList,
			ratings: [],
			form: {
				rating: null,
				comment: null,
			},
			currentPage: 0,
			mode: 'PENDING' /** COMPLETED */,
			comment: null,
		};
	},
	mounted() {},
	created() {
		if (this.dialog) {
			this.getPendingRating();
		}
	},
	watch: {
		value(newValue) {
			if (newValue) {
				this.getPendingRating();
			} else {
				this.ratings = [];
				this.mode = 'PENDING';
			}
		},
	},
	methods: {
		...mapActions('rating', [
			'getPendingRatingByTicket',
			'getRatings',
			'createRatings',
		]),

		async getPendingRating() {
			this.loading = true;
			this.mode = 'PENDING';
			this.comment = null;

			const { ok, response, error } = await this.getPendingRatingByTicket({
				ticketId: this.ticketId,
			});
			if (ok) {
				this.ratings = Array.isArray(response) ? response : [];
				if (this.ratings?.length === 0) {
					this.getRatingSaves();
				}
				this.currentPage = 0;
			} else {
				this.showAlert('error', error);
			}
			this.loading = false;
		},

		async getRatingSaves() {
			this.loading = true;
			this.mode = 'COMPLETED';

			const { ok, response, error } = await this.getRatings({
				ticket: parseInt(this.ticketId),
			});
			if (ok) {
				let items = response?.ticketRating;

				this.form.comment = response?.ticketRating[0].comment;

				items = Array.isArray(items) ? items : [];
				this.ratings = items.map((item) => {
					return { ...item, description: item?.rating?.description };
				});
				this.currentPage = 0;
			} else {
				this.showAlert('error', error);
			}
			this.loading = false;
		},

		validateSending: function () {
			const hasUnqualifiedCriteria = this.ratings?.some(
				(rating) => rating?.score == null
			);
			if (hasUnqualifiedCriteria) {
				this.$swal({
					title: 'Calificación de atención',
					text: '¿Deseas omitir la calificación de los criterios restantes?',
					icon: 'question',
					showCancelButton: true,
					confirmButtonText: 'Sí, omitir',
					cancelButtonText: 'Cancelar',
					confirmButtonColor: '#0039a6',
					cancelButtonColor: 'grey',
				}).then((result) => {
					if (result.isConfirmed) {
						this.saveRatings();
					}
				});
			} else {
				this.saveRatings();
			}
		},

		async saveRatings(/*omitted = false*/) {
			// const score = this.ratings[this.currentPage].score;
			// const comment = this.ratings[this.currentPage].comment;
			// if ((score && comment) || omitted) {
			// 	this.loadingSave = true;
			// 	const data = {
			// 		ticket: this.ticketId,
			// 		rating: this.ratings[this.currentPage].id,
			// 		score,
			// 		comment,
			// 		omitted,
			// 	};
			// 	const { ok, error } = await this.createRatings(data);
			// 	if (ok) {
			// 		if (this.currentPage + 1 == this.ratings.length) {
			// 			this.dialog = false;
			// 			this.ratings = [];
			// 			this.$emit('refresh');
			// 		}
			// 		if (this.currentPage + 1 < this.ratings?.length) {
			// 			this.currentPage++;
			// 		}
			// 	} else {
			// 		this.showAlert('error', error);
			// 	}
			// 	this.loadingSave = false;
			// } else {
			// 	this.showAlert('warning', 'Completar formulario.');
			// }
			const ratings = this.ratings.map((rating) => {
				return {
					rating: rating?.id,
					score: rating?.score || null,
					omitted: !rating?.score,
				};
			});
			let bodyParams = {
				company_id: parseInt(localStorage.getItem('company_id')),
				ticket: parseInt(this.ticketId),
				comment: this.form.comment,
				ratings,
			};

			const { ok, error } = await this.createRatings(bodyParams);
			if (ok) {
				this.dialog = false;
				this.ratings = [];
				this.comment = null;
				this.$emit('refresh');
			} else {
				this.showAlert('error', error);
			}
		},

		prevRating() {
			if (this.currentPage > 0) {
				this.currentPage--;
			}
		},

		async nextRating(omitted = false) {
			if (this.mode === 'PENDING') {
				await this.saveRatings(omitted);
			} else {
				this.currentPage++;
			}
		},

		skipRating() {
			this.nextRating(true);
		},

		showAlert(type = 'success', message) {
			if (type == 'error') {
				const errors = message?.data?.message;
				message = Array.isArray(errors) ? errors.join(', ') : errors;
			}
			$EventBus.$emit('showSnack', type, message);
		},
	},
	components: {
		RatingIcons,
	},
};
