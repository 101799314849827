import { mapState, mapActions } from 'vuex';
import DropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
	name: 'ReassignUserDialog',
	props: {
		value: Boolean,
		item: Object,
		source:String
	},
	data() {
		return {
			listError: [],
			valid: false,
			loadingSave: false,
			reassignUserForm: {
				new_responsible_id: null,
				//comment: '',
				//
				//files: null
			},
			responsibleRules: [(v) => !!v || 'El responsable es requerido'],
		};
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		// ...mapState({
		// 	responsibles: (state) => state.security.usersActive,
		// 	//...mapState('asset', ['allAssets']),
		// }),
		...mapState('user',['responsibles'])
	},
	created() {
	},
	methods: {
		// ...mapActions('spareHistory', ['assignAsset']),
		...mapActions('ticket', ['reassignResponsible']),
		
		async save() {
			this.$swal({
				text: '¿Estás seguro de que deseas realizar esta acción?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#0039a6',
				cancelButtonColor: 'grey',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sí',
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.listError = [];

					this.loadingSave = true;

					const { ok, error } = await this.reassignResponsible({
						id:this.item.id,
						data: this.reassignUserForm,
					});
					if (ok) {
						this.dialog = false;

						//OBS
						// this.$emit('maintenance-registered');
						// this.$emit('getAssetId');
						//[TODO]
						if(this.source === 'request-list'){
							this.$emit('tickets')
						}else{
							this.$emit('ticket')
						}

						this.reassignUserForm = {
							new_responsible_id:''
						};
						this.$nextTick(() => {
							if (this.$refs.form) {
								this.$refs.form.resetValidation();
							}
						});
					
						this.$emit('send');
					} else {
						this.listError = Array.isArray(error?.data?.message)
							? error?.data?.message
							: [error?.data?.message] || [];
					}

					this.loadingSave = false;
				}
			});
		},

		close() {
			this.dialog = false;
			this.reassignUserForm = {
				new_responsible_id:''
			};
			this.listError = [];
			this.$refs?.form?.resetValidation();
		},
		dialogInput(value) { 
			this.$nextTick(() => {
				//this.$refs.button.$el.blur();
				this.$refs.form.resetValidation();
				//this.$emit('dialogClosed', { saved: false });
				this.dialog = value;
				if (!value) {
					this.reassignUserForm = {
						new_responsible_id:''
					},
						this.listError = [];
				}
			})
		}
	},
	components: {
		DropZone
	}
};
