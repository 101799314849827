<template>
	<DetailMultipleAssignmentsReturn />
</template>
<script>
import DetailMultipleAssignmentsReturn from '@/components/inventory/assets/MultipleAssignments/DetailMultipleAssignmentsReturn/DetailMultipleAssignmentsReturn.vue';

export default {
	name: 'DetailMultipleAssignmentsReturnPage',
	components: { DetailMultipleAssignmentsReturn },
};
</script>
