<template>
	<AssetUnsubscribe />
</template>
<script>
import AssetUnsubscribe from '@/components/inventory/elements/AssetUnsubscribe/AssetUnsubscribe.vue';

export default {
	name: 'AssetUnsubscribePage',
	components: { AssetUnsubscribe },
};
</script>