<template>
	<DetailSpare />
</template>
<script>
//import HomePermissions from '@/components/permissions/home/index.vue';
import DetailSpare from '@/components/inventory/elements/Spare/DetailSpare/index.vue';

export default {
	name: 'DetailSparePage',
	components: { DetailSpare },
};
</script>
