import { mapActions } from 'vuex';
export default {
	name: 'CancelActiveSpareDialog',
	props: {
		value: Boolean,
		item: Object,
		source: String,
	},
	data() {
		return {
			listError: [],
			valid: false,
			loadingSave: false,
			cancelSpareForm: {
				unsubscribe_comment: '',
				files: [],
			},
			fileRules: [
				(value) =>
					(value && value.length > 0) || 'Debe subir al menos un archivo.',
			],
			commentRules: [
				(v) => !!v || 'Nombre de activo es requerido',
				(v) => v.length <= 400 || 'El nombre debe tener menos de 400 carácteres',
			],
		};
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		...mapActions('spare', ['cancelSpare']),
		async save() {
			if (!this.$refs?.form?.validate()) {
				return;
			}
			this.listError = [];
			const formData = new FormData();
			formData.append(
				'unsubscribe_comment',
				this.cancelSpareForm.unsubscribe_comment
			);

			this.cancelSpareForm.files.forEach((file) => {
				formData.append('spare_unsubscribe_evidences', file);
			});
			this.loadingSave = true;

			const { ok, error } = await this.cancelSpare({
				id: this.item.id,
				data: formData,
			});
			if (ok) {
				this.dialog = false;
				//puede que haya un emit
				//this.$emit('dialogClosed', { saved: true });
				switch (this.source) {
					case 'list':
						await this.$emit('getSpares');
						await this.$emit('closeCreateDialog');
						break;
					case 'detail':
						await this.$emit('getSpareById');
						break;
				}
				//
				this.cancelSpareForm = {
					unsubscribe_comment: '',
					files: [],
				};
				this.$refs.form.resetValidation();
			} else {
				this.listError = Array.isArray(error?.data?.message)
					? error?.data?.message
					: [error?.data?.message] || [];
			}
		},
		close() {
			this.dialog = false;
			//emit
			//this.$emit('dialogClosed', { saved: false });
			//
			this.cancelSpareForm = {
				unsubscribe_comment: '',
				files: [],
			};
			this.listError = [];
			this.$refs?.form?.resetValidation();
		},
		dialogInput(value) {
			this.$nextTick(() => {
				//this.$refs.button.$el.blur();
				this.$refs.form.resetValidation();
				//this.$emit('dialogClosed', { saved: false });
				this.dialog = value;
				if (!value) {
					this.cancelSpareForm = {
						unsubscribe_comment: '',
						files: [],
					};
					this.listError = [];
				}
			})
		}
	},
};
