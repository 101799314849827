import { showError } from "@/helpers/globalHelpers";
import SignerUserDialog from '@/components/inventory/elements/AddUserSignerDialog/AddUserSignerDialog.vue';
import DetailAssetAssignmentReturnDialog from '@/components/inventory/assets/MultipleAssignments/components/DetailAssetAssignmentReturnDialog/DetailAssetAssignmentReturnDialog.vue';
import DetailAssetAssignmentDialog from '@/components/inventory/assets/MultipleAssignments/components/DetailAssetAssignmentDialog/DetailAssetAssignmentDialog.vue';
import { mapActions, mapState } from "vuex";
import moment from 'moment';

export default {
  name: 'DetailMultipleAssignmentsView',

  data() {
    return {
      loading: false,
      loadingDownloadCertificateAssignment: false,
      userSignerDialog: false,
      isLoading:false,
      tab: 0,
      headersAssetAssignmentDetail: [
        { text: 'Activo', sortable: false, value: 'asset.name' },
        { text: 'Estado', sortable: false, value: 'status' },
        // { text: "Número de serie", sortable: false, value: "asset.num_asset" },
        { text: "Tipo", sortable: false, value: "asset.asset_type.name" },
        { text: "Área", sortable: false, value: "asset.area.name" },
        { text: 'Fecha de asignación', sortable: false, value: 'assignment_start_date' },
        { text: 'Fecha de desasignación', sortable: false, value: 'assignment_end_date' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      headerAssetAssignmentReturn: [
        { text: 'Razón', sortable: false, value: 'reason' },
        { text: 'Estado', sortable: false, value: 'status' },
        { text: 'Servicio', sortable: false, value: 'service.name' },
        { text: 'Usuario receptor', sortable: false, value: 'receptor_user.fullName' },
      ]
    }
  },

  created() {
    this.getAssetAssignmentById();
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Inicio',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'Dashboard',
          },
        },
        {
          text: 'Inventario',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'InventoryHome',
          },
        },
        {
          text: 'Activos',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "AssetsPermissions",
          },
        },
        {
          text: 'Lista de asignaciones',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "MultipleAssignmentsList",
          },
        },
        {
          text: 'Detalle de asignación',
          link: true,
          exact: true,
          disabled: true,
          // to: {
          // 	name: "InventoryHome",
          // },
        },
      ];
    },
    ...mapState('assetAssignment', ['assetAssignment']),
    isAssigned() {
      return this.assetAssignment?.status !== 'ASIGNADO';
    },
    isPreAssigned() {
      return this.assetAssignment?.status !== 'PREASIGNADO';
    },
    setLastSigner() {
      const findUserSignerAditional = this.assetAssignment?.assetAssignSigner?.find(signerAditional => signerAditional.status === 'PENDIENTE'|| signerAditional.status ==='CONFIRMADO' && signerAditional.type === 'ASIGNACION')
      return findUserSignerAditional ? findUserSignerAditional?.user_signer?.fullName : '-'
    },

    setLastSignerReturn(){
      const findUserSignerAditional = this.assetAssignment?.assetAssignSigner?.find(signerAditional => signerAditional.status === 'PENDIENTE'|| signerAditional.status ==='CONFIRMADO' && signerAditional.type === 'DEVOLUCION')
      return findUserSignerAditional ? findUserSignerAditional?.user_signer?.fullName : '-'
    }

  },

  methods: {
    ...mapActions('assetAssignment', ['getAssignmentById', 'notificationAssetAssignmentManual', 'getAssetAssignmentCertificateById','cancelPreAssignmentAsset']),

    async getAssetAssignmentById() {
      const { assetAssignmentId } = this.$route.params
      this.loading = true;
      const { error } = await this.getAssignmentById(assetAssignmentId);
      if (error) showError(error);
      this.loading = false;

    },

    addUserSigner() {
      this.userSignerDialog = true;
      this.$nextTick(() => {
        document.activeElement.blur();
      });
    },

    async manualNotification() {
      const { error } = await this.notificationAssetAssignmentManual({ id: this.assetAssignment?.id });
      if (error) showError(error);

      this.$swal({
        title: '¡Notificación enviada!',
        text: 'Te notificaremos por correo ¡Agradecemos tu paciencia!',
        icon: 'success',
        confirmButtonColor: '#0039a6',
      });
    },

    async downloadCertificate() {
      try {
        this.loadingDownloadCertificateAssignment = true;
        const { ok, response } = await this.getAssetAssignmentCertificateById({ id: this.assetAssignment?.id, data: Intl.DateTimeFormat().resolvedOptions().timeZone });
        if (ok) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'acta_entrega.pdf');
          document.body.appendChild(link);
          link.click();
        }
        this.loadingDownloadCertificateAssignment = false;
      } catch (error) {
        showError(error);
      }
    },

    setColorStatusAssignment(status) {
      switch (status) {
        case 'PREASIGNADO':
          return {
            background: 'light-blue lighten-5',
            color: 'light-blue',
            name: status,
          };
        case 'ANULADO':
          return {
            background: 'red lighten-5',
            color: 'red',
            name: status,
          };
        case 'DECLINADO':
          return {
            background: '#FFF3E0',
            color: 'orange',
            name: status,
          };
        case 'ASIGNADO':
          return {
            background: 'green lighten-5',
            color: 'green',
            name: status,
          };
        case 'DESASIGNADO':
          return {
            background: 'grey lighten-3',
            color: 'grey',
            name: status,
          };
        case 'DESASIGNADO_BAJA':
          return {
            background: 'grey lighten-4',
            color: 'grey',
            name: 'DESASIGNADO-BAJA',
          };

      }
    },

    setColorStatusAssignmentReturn(status) {
      switch (status) {
        case 'PENDIENTE':
          return {
            background: 'light-blue lighten-5',
            color: 'light-blue',
            name: status,
          };

        case 'CONFIRMADO':
          return {
            background: 'green lighten-5',
            color: 'green',
            name: status,
          };
      }
    },

    declineAssignmentAsset() {
      this.$swal({
          text: '¿Estás seguro de anular la asignación?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0039a6',
          cancelButtonColor: 'grey',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Sí, anular',
      }).then(async result => {
          if (result.isConfirmed) {
              this.isLoading = true;
              // const { assetAssignId } = this.$route.params;
              const { error } = await this.cancelPreAssignmentAsset({ id: this.assetAssignment?.id });
              if (error) showError(error);
              await this.getAssetAssignmentById()
              this.isLoading = false;
          }
      });
  },

    formatDate(dateString) {
      if (!dateString) {
        return '-';
      }
      return moment(dateString).format('DD/MM/YYYY HH:mm');
    },
  },

  beforeDestroy() {

  },

  components: {
    SignerUserDialog,
    DetailAssetAssignmentReturnDialog,
    DetailAssetAssignmentDialog
  }
}