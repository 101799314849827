
export default {
    name: "UserAdditionalDialog",
    data() {
        return {
            headerAdditionalUser: [
                { text: 'Nombre de usuario', sortable: false, value: 'user_additional.fullName' },
                { text: "Email", sortable: false, value: "user_additional.email" },
              ],
            // addUserForm: {
            //     user_additional: null,
            //     ticket_id: null,
            //     description: null
            // },
            // responsablesRules: [(v) => !!v || 'El usuario es requerido'],
            // loadingSave: false,
            listError: [],
        }
    },

    props: {
        value: Boolean,
        item: Object,
    },

    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    
    },

    created() {
       
    },

    methods: {
        

     

        close() {
            this.dialog = false;
            // (this.addUserForm = {
            //     user_additional: null,
            //     ticket_id: null,
            //     description: null
            // }),
            //     (this.listError = []);
            // this.$refs?.form?.resetValidation();
        },

    },
};
