import { showError } from '@/helpers/globalHelpers';
import moment from 'moment';
import { mapActions } from 'vuex';
import AssignUserDialog from '../../elements/AssignUserDialog';
import ReturnToWarehouseDialog from '@/components/inventory/elements/ReturnToWarehouseDialog/ReturnToWarehouseDialog.vue';

export default {
    name: 'AssignmentTab',

    inject: {
        theme: {
            default: () => ({ isDark: false }),
        },
    },

    data() {
        return {
            isLoading: false,
            asset: null,
            returnAssetDialog: false,
            assignUserDialog: false,
            headersAssignmentsHistory: [
				{
					text: 'Usuario',
					sortable: false,
					align: 'start',
					value: 'asset_assignment.assigned_user.fullName',
				},
				{
					text: 'Fecha de Asignación',
					sortable: false,
					align: 'start',
					value: 'assignment_start_date',
				},
				{
					text: 'Fecha de Desasignación',
					sortable: false,
					align: 'start',
					value: 'assignment_end_date',
				},
				{
					text: 'Estado',
					sortable: false,
					value: 'status',
				},
				{
					text: 'Acciones',
					sortable: false,
					align: 'start',
					value: 'actions',
				},
			],
        }
    },

    created() {
        this.getAssetId();
    },

    computed: {
    },

    methods: {
        ...mapActions('asset', ['getAssetById']),

        async getAssetId() {
			this.isLoading = true;

			const id = this.$route.params.assetId;
			const relations = 'assignments';
			const { ok, response, error } = await this.getAssetById({ id, relations });

			if (ok) {
				this.asset = response;
			} else {
				showError(error)
			}

			this.isLoading = false;
		},

        returnAsset() {
			this.returnAssetDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

		assignAsset() {
			this.assignUserDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

        formatDate(dateString, hour = true) {
			if (!dateString) {
				return '-';
			}
			return hour ? moment(dateString).format('DD/MM/YYYY HH:mm') : moment(dateString).format('DD/MM/YYYY')
		},

        setColorStatusAssignment(status) {
			switch (status) {
				case 'PREASIGNADO':
					return {
						background: 'light-blue lighten-5',
						color: 'light-blue',
						name: status,
					};
				case 'ANULADO':
					return {
						background: 'red lighten-5',
						color: 'red',
						name: status,
					};
				case 'DECLINADO':
					return {
						background: '#FFF3E0',
						color: 'orange',
						name: status,
					};
				case 'ASIGNADO':
					return {
						background: 'green lighten-5',
						color: 'green',
						name: status,
					};
				case 'DESASIGNADO':
					return {
						background: 'grey lighten-3',
						color: 'grey',
						name: status,
					};
				case 'DESASIGNADO_BAJA':
					return {
						background: 'grey lighten-4',
						color: 'grey',
						name: 'DESASIGNADO-BAJA',
					};

			}
		},

        refreshPage() {
			window.location.reload();
		},
    },

    beforeDestroy() {
    },

    components: {
        AssignUserDialog,
        ReturnToWarehouseDialog,
    },
}