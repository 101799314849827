import moment from 'moment';

export default {
    name: 'AssetDetailTab',

    data() {
        return {
           
        };
    },

    props: {
		item: Object,
	},

    created() {
    },

    computed: {
        setResponsibleData() {
			if (this.item) {
				if (this.item?.assigned_user?.name && this.item?.assigned_user?.email) {
					return `${this.item?.assigned_user?.name} - ${this.item?.assigned_user?.email}`
				}
				else if (this.item?.assigned_user?.name && !this.item?.assigned_user?.email) {
					return `${this.item?.assigned_user?.name}`
				}
				else if(!this.item?.assigned_user?.name && this.item?.assigned_user?.email){
					return `${this.item?.assigned_user?.name}` 
				}
				else {
					return '-'
				}
			}
		},
    },

    methods: {

        formatDate(dateString, hour = true) {
			if (!dateString) {
				return '-';
			}
			return hour ? moment(dateString).format('DD/MM/YYYY HH:mm') : moment(dateString).format('DD/MM/YYYY')
		},

        setColorStatus(status) {
			switch (status) {
				case 'DISPONIBLE':
					return {
						background: 'green lighten-5',
						color: 'green',
						name: status,
					};
				case 'PREASIGNADO':
					return {
						background: 'light-blue lighten-5',
						color: 'light-blue',
						name: status,
					};
				case 'ASIGNADO':
					return {
						background: 'blue lighten-5',
						color: 'blue',
						name: status,
					};
				case 'REVISION':
					return {
						background: '#FFF3E0',
						color: 'orange',
						name: status,
					};
				case 'MANTENIMIENTO':
					return {
						background: 'purple lighten-5',
						color: 'purple',
						name: status,
					};
				case 'BAJA':
					return {
						background: 'grey lighten-3',
						color: 'grey',
						name: status,
					};
				case 'OBSERVADO':
					return {
						background: 'grey lighten-2',
						color: 'grey',
						name: status,
					};
			}
		},
    },


    beforeDestroy() {
    },

    components: {
    },
}