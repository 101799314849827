import RegisterSpareDialog from '../../elements/RegisterSpareDialog';

export default {
  name: "ReturnAssets",
  data() {
    return {
      listError: [],
      valid: false,
      isLoading: false,
      headers: [
        {
          text: 'Activo',
          sortable: false,
          align: 'start',
          value: 'asset'
        },
        {
          text: 'Número de serie',
          sortable: false,
          align: 'start',
          value: 'serial_number'
        },
        {
          text: 'Motivo',
          sortable: false,
          align: 'start',
          value: 'reason'
        },
        {
          text: 'Estado',
          sortable: false,
          align: 'start',
          value: 'status'
        },
        {
          text: 'Comentarios',
          sortable: false,
          align: 'start',
          value: 'comment'
        },
        {
          text: 'Evidencias',
          sortable: false,
          align: 'start',
          value: 'evidences'
        },
        {
          text: 'Acciones',
          sortable: false,
          align: 'start',
          value: 'actions'
        }
      ],
      items: [],
      registerSpareDialog: false
    };
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Inicio',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'Dashboard',
          },
        },
        {
          text: 'Inventario',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'InventoryHome',
          },
        },
        {
          text: 'Activos',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'AssetsPermissions',
          },
        },
        {
          text: 'Devolución de Activos',
          link: true,
          exact: true,
          disabled: false,
          // to: {
          // 	name: 'InventoryHome',
          // },
        },
      ];
    }
  },

  created() {
    this.loadDataDummy();
  },

  methods: {
    loadDataDummy() {
      this.items = [
        {
          id: 1,
          asset: 'iPhone 15 Pro Max',
          serial_number: 'IPHONE15'
        },
        {
          id: 2,
          asset: 'Laptop HP Pavilion 15',
          serial_number: 'HP15PAV'
        }
      ]
    },

    async save() {
      this.$swal({
        text: '¿Está seguro de devolver estos activos?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0039a6',
        cancelButtonColor: 'grey',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sí, devolver',
      }).then(async (result) => {
        if (result.isConfirmed) {
          //this.$router.push({ name: 'TransfersPermissions' });
        }
      });
    }
  },

  components: {
		RegisterSpareDialog
	},
};
