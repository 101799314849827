var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isLoading)?_c('div',[_c('v-sheet',{staticClass:"pa-3",attrs:{"color":`grey ${_vm.theme.isDark ? 'darken-2' : 'lighten-4'}`}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"100%","type":"table-tbody"}})],1)],1):_c('div',[_c('v-card',{staticClass:"pa-4",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-card-title',[_vm._v("Lista de Mantenimientos")]),_c('div',{staticClass:"ml-auto",staticStyle:{"margin":"10px 0px 10px 0px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.asset?.status === 'DISPONIBLE' ? false : true,"color":"primary"},on:{"click":function($event){return _vm.registerMaintenance()}}},'v-btn',attrs,false),on),[_vm._v(" Mantenimiento"),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-wrench-clock ")])],1)]}}])},[_c('span',[_vm._v("Mantenimiento ")])])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.asset?.maintenances,"items-per-page":10,"dense":""},scopedSlots:_vm._u([{key:`item.status`,fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","color":_vm.getStatusColor(item.status)?.background,"text-color":_vm.getStatusColor(item.status)?.color}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:`item.maintenance_start_date`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.maintenance_start_date))+" ")]}},{key:`item.maintenance_end_date`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.maintenance_end_date))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{
									name: 'DetailMaintenanceInventory',
									params: {
										assetId: _vm.$route.params.assetId,
										assetMaintenanceId: item?.id,
									},
								}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])])]}},{key:`item.start_date`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.start_date))+" ")]}},{key:`item.end_date`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.end_date))+" ")]}},{key:`item.maintenance_cost`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.maintenance_cost ? item.maintenance_cost : '-')+" ")]}}],null,true)})],1),_c('register-asset-maintenance',{attrs:{"item":_vm.asset,"releaseAsset":false,"assetId":_vm.asset?.id},on:{"maintenance-registered":_vm.getAssetId},model:{value:(_vm.registerMaintenanceDialog),callback:function ($$v) {_vm.registerMaintenanceDialog=$$v},expression:"registerMaintenanceDialog"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }