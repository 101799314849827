var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isLoading)?_c('div',[_c('v-sheet',{staticClass:"pa-3",attrs:{"color":`grey ${_vm.theme.isDark ? 'darken-2' : 'lighten-4'}`}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"100%","type":"table-tbody"}})],1)],1):_c('div',[_c('v-card',{staticClass:"pa-4",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-card-title',[_vm._v("Lista de Usuarios Asignados")]),_c('div',{staticClass:"ml-auto",staticStyle:{"margin":"10px 0px 10px 0px"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","disabled":_vm.asset.status !== 'DISPONIBLE' &&
							_vm.asset.status !== 'ASIGNADO'},on:{"click":function($event){_vm.asset.status === 'ASIGNADO'
								? _vm.returnAsset(_vm.asset)
								: _vm.assignAsset(_vm.asset)}}},[_vm._v(" "+_vm._s(_vm.asset.status === 'ASIGNADO' ? 'Devolver' : 'Asignar a usuario')+" "),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.asset.status === 'ASIGNADO' ? 'mdi-backup-restore' : 'mdi-account-plus')+" ")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersAssignmentsHistory,"items":_vm.asset.assignments,"items-per-page":10},scopedSlots:_vm._u([{key:`item.assignment_start_date`,fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatDate(props.item.assignment_start_date))+" ")]}},{key:`item.assignment_end_date`,fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatDate(props.item.assignment_end_date))+" ")]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":_vm.setColorStatusAssignment(item?.status)?.background,"text-color":_vm.setColorStatusAssignment(item?.status)?.color,"small":""}},[_vm._v(" "+_vm._s(_vm.setColorStatusAssignment(item?.status)?.name)+" ")])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{
									name: 'AssignmentAssetDetail',
									params: {
										assetId: _vm.asset.id,
										assetAssignId: item.id,
									},
								}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])])]}}],null,true)}),_c('assign-user-dialog',{attrs:{"item":_vm.asset,"returnAsset":true},on:{"getAssetId":_vm.refreshPage},model:{value:(_vm.assignUserDialog),callback:function ($$v) {_vm.assignUserDialog=$$v},expression:"assignUserDialog"}}),_c('return-to-warehouse-dialog',{attrs:{"item":_vm.asset,"returnAsset":false},on:{"getAssetId":_vm.refreshPage},model:{value:(_vm.returnAssetDialog),callback:function ($$v) {_vm.returnAssetDialog=$$v},expression:"returnAssetDialog"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }