import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import ImportFileIcon from '@/components/request/elements/ImportFileIcon/ImportFileIcon.vue';
import { showError } from '@/helpers/globalHelpers';
import { showSnackBar } from '@/helpers/globalHelpers';

export default {
	name: 'TicketComments',
	props: {
		ticket_attention: Number,
		value: Boolean,
		request: Object,
		status: String,
		userType: String,
		showSendComment: Boolean,
		userConfirmDialog:Boolean,
	},
	data() {
		return {
			types: ['CONSULTA', 'RESOLUCION'],
			selectedType: this.userType == 'RESPONSIBLE' ? 'RESOLUCION' : 'CONSULTA',
			comment: null,
			loading: false,
			loadingSave: false,
			showMessages: false,
			files: [],
			mode: 'SHOW', // WRITE
			company_id: parseInt(localStorage.getItem('company_id')),
			ticketCommentfilters: {
				page: 1,
				limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
				pagination: false,
				description: null,
				status: null,
			},
		};
	},

	computed: {
		...mapState('ticketComment', [
			'ticketComments',
			'ticketCommentsPagination',
			'loadingTicketComment',
		]),
	},
	mounted() {},
	created() {
		this.getTicketComments();
	},
	methods: {
		...mapActions('ticketComment', [
			'listTicketComments',
			'cleanTicketComments',
			'createTicketComment',
			'getFileUrl',
		]),
		changeFilter() {},

		setScrollBottom() {
			this.$nextTick(() => {
				let miDiv = document.getElementById('wrapper-chat');
				if (miDiv) {
					miDiv.scrollTop = miDiv.scrollHeight;
				}
			});
		},

		async getTicketComments() {
			this.loading = true;
			const payload = {
				company_id: this.company_id,
				ticket_attention: this.ticket_attention,
			};
			const { ok, error } = await this.listTicketComments(payload);
			this.loading = false;
			this.showMessages = ok;
			if (ok) {
				this.setScrollBottom();
			} else {
				showError(error);
			}
		},

		formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},

		async saveTicketComment() {
			try {
				this.loadingSave = true;
				let formData = new FormData();
				// formData.append(
				// 	'company_id',
				// 	parseInt(localStorage.getItem('company_id'))
				// );
				formData.append('ticket_attention', this.ticket_attention);
				formData.append('type', this.selectedType);
				if (this.comment) {
					formData.append('content', this.comment);
				}

				if (Array.isArray(this.files)) {
					this.files.forEach((file) => {
						formData.append('files', file, file?.name);
					});
					this.$emit('refresh', this.selectedType);
				}
				const { ok, error } = await this.createTicketComment(formData);
				if (ok) {
					this.mode = 'SHOW';
					this.getTicketComments();
					this.setScrollBottom();
					this.comment = null;
					this.files = [];
				} else {
					showError(error);
				}
			} catch (error) {
				alert('Error al guardar el comentario:');
			} finally {
				this.loadingSave = false;
			}
		},

		getNamefile(url) {
			try {
				const urlParts = url.split('/');
				const fileNameWithTimestamp = urlParts[urlParts.length - 1];
				const fileNameParts = fileNameWithTimestamp.split('-');
				fileNameParts.shift();
				const fileName = fileNameParts.join('-');
				return fileName;
			} catch (error) {
				return 'EVIDENCIA';
			}
		},

		getPositionComment(item) {
			return item?.creator_id?.id == this.$store.state.auth?.user?.id
				? 'comment-left'
				: 'comment-left';
		},

		openFile: function (item) {
			const url = item?.comment_file;
			this.getFileUrl({ url })
				.then((response) => {
					if (response) {
						window.open(response.response, '_blank');
					}
				})
				.catch((error) => {
					showSnackBar(
						error?.response?.data?.message || error.toString(),
						'error'
					);
				});
		},
	},

	beforeDestroy() {
		this.cleanTicketComments();
	},

	components: {
		ImportFileIcon,
	},
};
