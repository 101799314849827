import { mapActions } from 'vuex';

export default {
	name: 'ReestablishSpareDialog',
	data() {
		return {
			listError: [],
			loadingSave:false
		};
	},
	props: {
		value: Boolean,
		item: Object,
		source: String,
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		...mapActions('spare', ['reestablishSpare']),
		close() {
			this.dialog = false;
			this.$emit('dialogClosed', { saved: false });
		},
		async reestablishItemConfirm() {
			if (this.item) {
				this.loadingSave = true;
				const { ok, error } = await this.reestablishSpare(this.item.id);

				if (ok) {
					this.close();
					this.$emit('dialogClosed', { saved: true });

					switch (this.source) {
						case 'list':
							await this.$emit('getSpares');
							break;
						case 'detail':
							await this.$emit('getSpareById');
							break;
					}
				} else {
					this.listError = Array.isArray(error?.data?.message)
						? error?.data?.message
						: [error?.data?.message] || [];
				}
			}
		},
		dialogInput(value) {
			this.$nextTick(() => {
				this.$emit('dialogClosed', { saved: false });
				this.dialog = value;
				if (!value) {
					this.listError = [];
				}
			})
		}
	},
};
