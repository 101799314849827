<template>
	<ConfirmAssignment />
</template>
<script>
//import HomePermissions from '@/components/permissions/home/index.vue';
import ConfirmAssignment from '@/components/inventory/elements/ConfirmAssignment/confirmAssignment.vue';

export default {
	name: 'ConfirmAssignmentPage',
	components: { ConfirmAssignment },
};
</script>
