import { mapState, mapActions } from 'vuex';
import DropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
	name: 'RegisterAssetMaintenance',
	props: {
		value: Boolean,
		item: Object,
		assetId: [String, Number],
		releaseAsset: Boolean,
	},
	data() {
		return {
			listError: [],
			valid: false,
			loadingSave: false,
			maintenanceAssetForm: {
				asset: null,
				type: null,
				comment: '',
				maintenance_cost:null
			
			},
			files: [],
			requiredRules: [(v) => !!v || 'El campo es requerido'],
			commentRules: [
				(v) => !!v || 'Comentario es requerido',
				(v) => v.length <= 400 || 'Comentario debe tener menos de 400 caracteres',
			],
			fileRules: [
				(value) =>
					(value && value.length > 0) || 'Debe subir al menos un archivo.',
			],
			maintenanceTypes: [
			
				{ id: 1, text: 'MANTENIMIENTO PREVENTIVO' },
				{ id: 2, text: 'MANTENIMIENTO CORRECTIVO' },
				{ id: 3, text: 'MANTENIMIENTO PREDICTIVO' },
				{ id: 4, text: 'OTROS' },
			],
			dropzoneOptions: {
				url: 'https://httpbin.org/post',
				thumbnailWidth: 150,
				maxFilesize: 0.5,
				headers: { 'My-Awesome-Header': 'header value' },
				dictDefaultMessage:
					'Arrastra archivos o haz clic aquí para subir evidencias',
				dictFallbackMessage:
					'Tu navegador no soporta la carga de archivos mediante arrastrar y soltar.',
				dictFileTooBig:
					'El archivo es demasiado grande ({{ fileSize }}MB). Tamaño máximo permitido: {{ maxFilesize }}MB.',
				dictInvalidFileType: 'No puedes subir archivos de este tipo.',
			},
		};
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		...mapState({
			responsibles: (state) => state.security.usersActive,
		}),
	},
	watch: {
		item: {
			immediate: true,
			handler: 'setValuesReleaseAsset',
		},
	},
	created() {
		
	},
	methods: {
		...mapActions('assetMaintenance', ['createAssetMaintenance', 'assetRelease']),

		async saveMaintenance() {
			this.$swal({
				text: '¿Estás seguro de que deseas realizar esta acción?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#0039a6',
				cancelButtonColor: 'grey',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sí',
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.listError = [];

					this.loadingSave = true;

					delete this.maintenanceAssetForm.files;
					const data = {
						...this.maintenanceAssetForm,
						asset: this.assetId,
					};

					const { ok, error } = await this.createAssetMaintenance({
						data: data,
					});
					if (ok) {
						this.dialog = false;

						this.$emit('maintenance-registered');
						this.$emit('getAssetId');

						this.maintenanceAssetForm = {
							asset: '',
							type: '',
							comment: '',
							maintenance_cost:''

						};
						this.$nextTick(() => {
							if (this.$refs.form) {
								this.$refs.form.resetValidation();
							}
						});
						this.files = [];
						this.$emit('send');
					} else {
						this.listError = Array.isArray(error?.data?.message)
							? error?.data?.message
							: [error?.data?.message] || [];
					}

					this.loadingSave = false;
				}
			});
		},

		async saveAssetRelease() {
			this.$swal({
				text: '¿Estás seguro de que deseas realizar esta acción?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#0039a6',
				cancelButtonColor: 'grey',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sí',
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.listError = [];

					this.loadingSave = true;

					const formData = new FormData();

					this.files.forEach((file) => {
						formData.append('asset_maintenance_evidences', file);
					});

					formData.append('maintenance_cost',this.maintenanceAssetForm.maintenance_cost)

					const { ok, error } = await this.assetRelease({
						id: this.item.id,
						data: formData,
					});
					if (ok) {
						this.dialog = false;

						this.$emit('maintenance-registered');
						this.$emit('getAssetId');
						this.$emit('getAssetMaintenanceId');

						this.maintenanceAssetForm = {
							asset: '',
							type: '',
							comment: '',
							maintenance_cost:''
						};

						this.files = [];

						this.$nextTick(() => {
							if (this.$refs.form) {
								this.$refs.form.resetValidation();
							}
						});
					} else {
						this.listError = Array.isArray(error?.data?.message)
							? error?.data?.message
							: [error?.data?.message] || [];
					}

					this.loadingSave = false;
				}
			});
		},
		
		close() {
			this.dialog = false;

			if (this.item.status === 'MANTENIMIENTO') {
				
				this.maintenanceAssetForm = {
					type: this.item.maintenances[0].type,
					comment: this.item.maintenances[0].comment,
				};

			} else {
				
				this.maintenanceAssetForm = {
					asset: '',
					type: '',
					comment: '',
					maintenance_cost:''
				}
				
			}
			this.files = [];
		
			this.listError = [];
			this.$refs?.form?.resetValidation();
			
		},

		buttonClickHandler() {
			if (!this.$refs?.form?.validate()) {
				return;
			}

			if (this.releaseAsset) {
				this.saveAssetRelease();
			} else {
				this.saveMaintenance();
			}
		},

		setValuesReleaseAsset() {
		
			if (
				this.item &&
				this.item.status === 'MANTENIMIENTO' &&
				this.item?.maintenances?.length &&
				this.item.maintenances[0].status === 'INICIADO'
			) {
				this.maintenanceAssetForm.type = this.item.maintenances[0].type;
				this.maintenanceAssetForm.comment = this.item.maintenances[0].comment;
			}
		},

		dialogInput(value) {
		
			this.$nextTick(() => {
				this.$refs.dialogForm.$el.blur();
				this.$refs.form.resetValidation();
				this.dialog = value;
				if (!value) {
					if (this.item.status === 'MANTENIMIENTO') {
					
						this.maintenanceAssetForm = {
							type: this.item.maintenances[0].type,
							comment: this.item.maintenances[0].comment,
						};

					} else {
						
						this.maintenanceAssetForm = {
							asset: '',
							type: '',
							comment: '',
							maintenance_cost:''
						}
						
					}
					this.files = [];
					this.listError = [];
				}
			});
		},
	},
	components: {
		DropZone,
	},
};
