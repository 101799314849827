<template>
	<CreateMultipleAssignments />
</template>
<script>
import CreateMultipleAssignments from '@/components/inventory/assets/MultipleAssignments/CreateMultipleAssignments/CreateMultipleAssignments.vue';

export default {
	name: 'CreateMultipleAssignmentsPage',
	components: { CreateMultipleAssignments },
};
</script>
