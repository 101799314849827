import { mapState, mapActions } from 'vuex';
import DropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
	name: 'ReturnToTiDialog',
	props: {
		value: Boolean,
		item: Object,
		//
		returnAsset: Boolean,
	},
	data() {
		return {
			listError: [],
			valid: false,
			loadingSave: false,
			assignUserForm: {
				asset: null,
				assigned_user_id: null,
				comment: '',
			},
			requiredRules: [(v) => !!v || 'El campo es requerido'],
			responsablesRules: [(v) => !!v || 'El responsable es requerido'],
			commentRules: [
				(v) => !!v || 'El comentario es requerido',
				(v) =>
					v.length <= 400 || 'El comentario debe tener menos de 400 carácteres',
			],
			motives: [
				{ id: 1, text: ' Termino de servicio' },
				{ id: 2, text: 'Despido' },
				{ id: 3, text: 'Vacaciones' },
			],
			conditions: [
				{ id: 1, text: 'Bueno' },
				{ id: 2, text: 'Malo' },
				{ id: 3, text: 'Deficiente' },
				{ id: 4, text: 'No entrego' },
			],
			dropzoneOptions: {
				url: 'https://httpbin.org/post',
				thumbnailWidth: 150,
				maxFilesize: 0.5,
				headers: { 'My-Awesome-Header': 'header value' },
				dictDefaultMessage: 'Arrastra archivos o haz clic aquí para subir evidencias',
				dictFallbackMessage: 'Tu navegador no soporta la carga de archivos mediante arrastrar y soltar.',
				dictFileTooBig: 'El archivo es demasiado grande ({{ fileSize }}MB). Tamaño máximo permitido: {{ maxFilesize }}MB.',
				dictInvalidFileType: 'No puedes subir archivos de este tipo.',
			},
		};
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		...mapState({
			responsibles: (state) => state.security.usersActive,
		}),
	},
	created() {
		this.getUsersActive();
	},
	methods: {
		...mapActions('assetHistory', ['assignUser']),
		...mapActions('security', ['getUsersActive']),
		async save() {
			// if (!this.$refs?.form?.validate()) {
			//   return;
			// }
			// this.listError = [];
			// const data = {
			//   ...this.assignUserForm,
			//   company_id: parseInt(localStorage.getItem("company_id")),
			//   asset: this.item.id,
			// };
			// this.loadingSave = true;
			// const { ok, error } = await this.assignUser(data);
			// if (ok) {
			//   this.dialog = false;
			//   //puede que haya un emit
			//   await this.$emit("getAssetId");
			//   this.assignUserForm = {
			//     asset: "",
			//     assigned_user_id: "",
			//     comment: "",
			//   };
			//   this.$nextTick(() => {
			//     if (this.$refs.form) {
			//       this.$refs.form.resetValidation();
			//     }
			//   });
			// } else {
			//   this.listError = Array.isArray(error?.data?.message)
			//     ? error?.data?.message
			//     : [error?.data?.message] || [];
			// }
			// Mostrar SweetAlert para confirmación antes de asignar el usuario.
			this.$swal({
				text: '¿Estás seguro de que deseas realizar esta acción?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#0039a6',
				cancelButtonColor: 'grey',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sí',
			}).then(async (result) => {
				if (result.isConfirmed) {
					if (!this.$refs?.form?.validate()) {
						return;
					}
					this.dialog = false;
					await this.$emit('getAssetId');
					this.assignUserForm = {
						asset: '',
						assigned_user_id: '',
						comment: '',
					};
				}
			});
		},
		close() {
			this.dialog = false;
			(this.assignUserForm = {
				asset: '',
				assigned_user_id: '',
				comment: '',
			}),
				(this.listError = []);
			this.$refs?.form?.resetValidation();
		},
	},
	components: {
		DropZone,
	},
};
