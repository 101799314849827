var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"px-0 pt-2 pb-0",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c('v-container',[_c('v-card',{staticClass:"pa-4 px-md-8 mb-8",attrs:{"rounded":"lg","flat":""}},[_c('v-row',{attrs:{"justify":"space-between","align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.setTitle))])],1),_c('v-col',{staticClass:"d-flex align-center justify-space-between flex-wrap",attrs:{"cols":"12","sm":"auto"}},[(_vm.tab == 0)?_c('v-btn',{attrs:{"to":{ name: 'CreateMultipleAssignments' },"color":"primary","outlined":""}},[_vm._v(" Registrar asignación ")]):_vm._e()],1)],1)],1),_c('v-card',{attrs:{"rounded":"lg","flat":""}},[_c('v-tabs',{attrs:{"background-color":"transparent","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Asignaciones")]),_c('v-tab',[_vm._v("Devoluciones")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"pa-2 pa-sm-4"},[_c('v-data-table',{staticClass:"rounded-lg pa-4 pa-md-8",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.allAssetsAssignment,"items-per-page":20,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mb-4",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.usersActive,"item-value":"id","item-text":"fullName","label":"Filtrar por responsable","placeholder":"Filtrar por responsable","hide-details":"","dense":"","clearable":"","outlined":""},on:{"change":() => _vm.getAssetAssignment()},model:{value:(
												_vm.assetAssignmentFilters.responsible
											),callback:function ($$v) {_vm.$set(_vm.assetAssignmentFilters, "responsible", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\tassetAssignmentFilters.responsible\n\t\t\t\t\t\t\t\t\t\t\t"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.usersActive,"item-value":"id","item-text":"fullName","label":"Filtrar por usuario asignado","placeholder":"Filtrar por usuario asignado","hide-details":"","dense":"","clearable":"","outlined":""},on:{"change":() => _vm.getAssetAssignment()},model:{value:(
												_vm.assetAssignmentFilters.assigned_user
											),callback:function ($$v) {_vm.$set(_vm.assetAssignmentFilters, "assigned_user", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\tassetAssignmentFilters.assigned_user\n\t\t\t\t\t\t\t\t\t\t\t"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.costCenters,"item-value":"id","item-text":"name","label":"Filtrar por servicio","placeholder":"Filtrar por servicio","hide-details":"","dense":"","clearable":"","outlined":""},on:{"change":() => _vm.getAssetAssignment()},model:{value:(_vm.assetAssignmentFilters.service),callback:function ($$v) {_vm.$set(_vm.assetAssignmentFilters, "service", $$v)},expression:"assetAssignmentFilters.service"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.status,"item-text":"name","item-value":"name","label":"Filtrar por Estado","placeholder":"Filtrar por Estado","hide-details":"","dense":"","clearable":"","outlined":""},on:{"change":() => _vm.getAssetAssignment()},model:{value:(_vm.assetAssignmentFilters.status),callback:function ($$v) {_vm.$set(_vm.assetAssignmentFilters, "status", $$v)},expression:"assetAssignmentFilters.status"}})],1)],1)]},proxy:true},{key:`item.preassignment_date`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.preassignment_date))+" ")]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{staticStyle:{"width":"150px","justify-content":"center","align-items":"center"},attrs:{"color":_vm.setColorStatusAssignment(item?.status)
											?.background,"text-color":_vm.setColorStatusAssignment(item?.status)?.color,"small":""}},[_vm._v(" "+_vm._s(_vm.setColorStatusAssignment(item?.status)?.name)+" ")])]}},{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{
													name: 'DetailMultipleAssignments',
													params: {
														assetAssignmentId: item.id,
													},
												}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])])],1)]}}],null,true)}),_c('div',{staticClass:"text-center py-4"},[_c('v-pagination',{attrs:{"length":_vm.assetAssignmentPagination.totalPages},on:{"input":function($event){return _vm.getAssetAssignment()}},model:{value:(_vm.assetAssignmentFilters.page),callback:function ($$v) {_vm.$set(_vm.assetAssignmentFilters, "page", $$v)},expression:"assetAssignmentFilters.page"}})],1)],1),_c('v-tab-item',{staticClass:"pa-2 pa sm-4"},[_c('v-data-table',{staticClass:"rounded-lg pa-4 pa-md-8",attrs:{"loading":_vm.loading,"headers":_vm.headersAssignmentReturn,"items":_vm.allassetsAssignmentReturn,"items-per-page":20,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mb-4",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.usersActive,"item-value":"id","item-text":"fullName","label":"Filtrar por responsable","placeholder":"Filtrar por responsable","hide-details":"","dense":"","clearable":"","outlined":""},on:{"change":() => _vm.getAssetAssignmentReturn()},model:{value:(
												_vm.assetAssignmentReturnFilters.user_assigned
											),callback:function ($$v) {_vm.$set(_vm.assetAssignmentReturnFilters, "user_assigned", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\tassetAssignmentReturnFilters.user_assigned\n\t\t\t\t\t\t\t\t\t\t\t"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.usersActive,"item-value":"id","item-text":"fullName","label":"Filtrar por usuario receptor","placeholder":"Filtrar por usuario receptor","hide-details":"","dense":"","clearable":"","outlined":""},on:{"change":() => _vm.getAssetAssignmentReturn()},model:{value:(
												_vm.assetAssignmentReturnFilters.receptor_user
											),callback:function ($$v) {_vm.$set(_vm.assetAssignmentReturnFilters, "receptor_user", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\tassetAssignmentReturnFilters.receptor_user\n\t\t\t\t\t\t\t\t\t\t\t"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.costCenters,"item-value":"id","item-text":"name","label":"Filtrar por servicio","placeholder":"Filtrar por servicio","hide-details":"","dense":"","clearable":"","outlined":""},on:{"change":() => _vm.getAssetAssignmentReturn()},model:{value:(
												_vm.assetAssignmentReturnFilters.service
											),callback:function ($$v) {_vm.$set(_vm.assetAssignmentReturnFilters, "service", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\tassetAssignmentReturnFilters.service\n\t\t\t\t\t\t\t\t\t\t\t"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"items":_vm.statusAssetAssignmentReturn,"item-text":"name","item-value":"name","label":"Filtrar por Estado","placeholder":"Filtrar por Estado","hide-details":"","dense":"","clearable":"","outlined":""},on:{"change":() => _vm.getAssetAssignmentReturn()},model:{value:(
												_vm.assetAssignmentReturnFilters.status
											),callback:function ($$v) {_vm.$set(_vm.assetAssignmentReturnFilters, "status", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\tassetAssignmentReturnFilters.status\n\t\t\t\t\t\t\t\t\t\t\t"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"items":_vm.reason,"item-text":"name","item-value":"name","label":"Filtrar por razón","placeholder":"Filtrar por razón","hide-details":"","dense":"","clearable":"","outlined":""},on:{"change":() => _vm.getAssetAssignmentReturn()},model:{value:(
												_vm.assetAssignmentReturnFilters.reason
											),callback:function ($$v) {_vm.$set(_vm.assetAssignmentReturnFilters, "reason", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\tassetAssignmentReturnFilters.reason\n\t\t\t\t\t\t\t\t\t\t\t"}})],1)],1)]},proxy:true},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{staticStyle:{"width":"150px","justify-content":"center","align-items":"center"},attrs:{"color":_vm.setColorStatusAssignmentReturn(item?.status)
											?.background,"text-color":_vm.setColorStatusAssignmentReturn(item?.status)
											?.color,"small":""}},[_vm._v(" "+_vm._s(_vm.setColorStatusAssignmentReturn(item?.status) ?.name)+" ")])]}},{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{
													name: 'DetailMultipleAssignmentsReturn',
													params: {
														assetAssignmentReturnId: item.id,
													},
												}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])])],1)]}}],null,true)}),_c('div',{staticClass:"text-center py-4"},[_c('v-pagination',{attrs:{"length":_vm.assetAssignmentReturnPagination.totalPages},on:{"input":function($event){return _vm.getAssetAssignmentReturn()}},model:{value:(_vm.assetAssignmentReturnFilters.page),callback:function ($$v) {_vm.$set(_vm.assetAssignmentReturnFilters, "page", $$v)},expression:"assetAssignmentReturnFilters.page"}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }