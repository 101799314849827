import { mapActions, mapState } from 'vuex';
import TicketComments from '@/components/request/elements/TicketComments/TicketComments.vue';
import CloseTicketDialog from '@/components/incidents/elements/CloseTicket/CloseTicket.vue';
//import CalificationTicketDialog from '@/components/incidents/elements/CalificationsTicket/Calificationticket.vue';
import AssignAssets from '@/components/incidents/elements/AssignAssets/AssignAssets.vue';
import RatingDialog from '@/components/request/elements/RatingDialog/RatingDialog.vue';
import { getIncidentStatus } from '@/helpers/statusIncident.js';
import moment from 'moment';
import TicketDetailElement from '@/components/request/elements/TicketDetailElement/TicketDetailElement.vue';
import UserAdditionalDialog from '@/components/request/elements/UserAdditionalDialog/UserAdditionalDialog.vue';


export default {
	name: 'DetailTicket',
	data() {
		return {
			isLoading: false,
			listError: [],
			closeTicketDialog: false,
			additionalUsersDialog: false,
			item: {},
			ticket_attention: null,
			isRatingActive: false,
		};
	},
	computed: {
		breadcrumbs() {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Incidentes',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'IncidentsHome',
					},
				},
				{
					text: 'Tickets',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'IncidentList',
					},
				},
				{
					text: 'Detalle de Ticket',
					link: true,
					exact: true,
					disabled: true,
				},
			];
		},
		...mapState('ticket', ['ticket']),
		isColumns() {
			return (
				this.ticket?.ticket_attention &&
				!['SOLICITADO', 'CANCELADO'].includes(this.ticket?.status)
			);
		},
		...mapState('auth', ['user']),
		confirmedTicket() {
			if (this.ticket && this.ticket.assigned_user_id?.id === this.user.id) {
				return true;
			} else if (this.ticket && this.ticket.status === 'CALIFICADO') {
				return true;
			} else {
				return false;
			}
		},
		showDetailTicketIncident() {
			// Retorna false inmediatamente si no hay ticket o si todavía está cargando para prevenir mostrar el mensaje de no permisos.
			if (!this.ticket) {
				return false;
			}
			return this.ticket.assigned_user_id?.id === this.user?.id ||
				this.ticket.ticket_additional_users?.some(ticketAddUser => ticketAddUser?.user_additional?.id === this.user?.id);
		},
	},
	created() {
		this.getTicketId();
	},
	methods: {
		getIncidentStatus,
		...mapActions('ticket', ['getTicketById', 'cleanTicket']),
		async getTicketId() {
			this.isLoading = true;
			const id = this.$route.params.incidentId;
			try {
				const { ok, response, error } = await this.getTicketById(id);
				if (ok) {
					this.ticket_attention = response?.ticket_attention || null;
				} else {
					this.listError = Array.isArray(error?.data?.message)
						? error?.data?.message
						: [error?.data?.message] || [];
				}
			} catch (error) {
				alert(error);
			} finally {
				this.isLoading = false;
			}
		},
		formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},
		downloadFile(filePath) {
			const fileName = filePath.split('/').pop();
			let a = document.createElement('a');
			a.href = filePath;
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		},
		printSubtype(item) {
			return (
				item?.subtype?.classification?.area?.name +
				' / ' +
				item?.subtype?.classification?.name +
				' / ' +
				item?.subtype?.name
			);
		},

		printAssets(assets) {
			let assetsText = [];
			if (Array.isArray(assets)) {
				assets.forEach((asset) => {
					assetsText.push(asset?.name + ' ' + asset?.serial_number);
				});
			}
			return assetsText.join(', ');
		},

		async listenCloseTicket() {
			this.cleanTicket();
			await this.getTicketId();
			this.isRatingActive = true;
		},

		getNamefile(url) {
			try {
				const urlParts = url.split('/');
				const fileNameWithTimestamp = urlParts[urlParts.length - 1];
				const fileNameParts = fileNameWithTimestamp.split('-');
				fileNameParts.shift();
				const fileName = fileNameParts.join('-');
				return fileName;
			} catch (error) {
				return 'EVIDENCIA';
			}
		},
		additionalUsers() {
			this.additionalUsersDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
	},
	beforeDestroy() {
		this.cleanTicket();
	},
	components: {
		TicketComments,
		CloseTicketDialog,
		AssignAssets,
		//CalificationTicketDialog,
		RatingDialog,
		TicketDetailElement,
		UserAdditionalDialog
	},
};
