<template>
	<DetailTicket />
</template>
<script>
//import HomePermissions from '@/components/permissions/home/index.vue';
import DetailTicket from '@/components/incidents/elements/DetailTicket/DetailTicket.vue';

export default {
	name: 'DetailTicketPage',
	components: { DetailTicket },
};
</script>
