import { showError } from "@/helpers/globalHelpers";
import { mapActions, mapState } from "vuex";
import SignerUserReturnDialog from '@/components/inventory/assets/MultipleAssignments/components/AddSignerUserReturnDialog/index.vue';
import moment from 'moment';
export default {
  name: 'ReturnAssignmentDetail',

  data() {
    return {
      // datos iniciales del componente
      loading: false,
      userSignerReturnDialog: false,
      tab: 0,
      loadingDownloadCertificateAssignment: false,
      headerAssetAssignmentReturn: [
        // { text: 'Razón', sortable: false, value: 'reason' },
        { text: 'Estado', sortable: false, value: 'status' },
        { text: 'Activo', sortable: false, value: 'asset_assign.asset.name' },
        { text: 'Número de activo', sortable: false, value: 'asset_assign.asset.num_asset' },
        { text: 'Tipo de activo', sortable: false, value: 'asset_assign.asset.asset_type.name' },
        { text: 'Estado de devolución de activo', sortable: false, value: 'asset_status' },
        // { text: 'Acciones', value: 'actions', sortable: false },
      ]
    };
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Inicio',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'Dashboard',
          },
        },
        {
          text: 'Inventario',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'InventoryHome',
          },
        },
        {
          text: 'Activos',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "AssetsPermissions",
          },
        },
        {
          text: 'Lista de devoluciones',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "MultipleAssignmentsList",
          },
        },
        {
          text: 'Detalle de devolución',
          link: true,
          exact: true,
          disabled: true,
          // to: {
          // 	name: "InventoryHome",
          // },
        },
      ];
    },
    ...mapState('assetAssignment', ['assetAssignmentReturn',]),
    isPending() {
      return this.assetAssignmentReturn?.status !== 'PENDIENTE';
    },
    evidences() {
      const evidenceFiles = new Set();

      this.assetAssignmentReturn.assetAssignReturnDetails.forEach(detail => {
        detail.evidence_files.forEach(evidence => {
          evidenceFiles.add(evidence.evidence_file);
        });
      });

      return Array.from(evidenceFiles);
    },

    setLastSignerReturn() {
      const findUserSignerAditional = this.assetAssignmentReturn?.assetAssignSignerReturn.find(signerAditional => signerAditional.status === 'PENDIENTE' || signerAditional.status === 'CONFIRMADO')
      return findUserSignerAditional ? findUserSignerAditional?.user_signer?.fullName : '-'
    }
  },

  watch: {
    // observadores

  },

  created() {
    // hook creado, bueno para inicializar datos o llamadas de API
    this.getAssignmentReturnId()
  },

  mounted() {
    // hook montado, acceso al DOM y refs, integraciones de plugins

  },

  beforeUpdate() {
    // justo antes de que la actualización del DOM ocurra

  },

  updated() {
    // justo después de que la actualización del DOM ocurre

  },

  beforeDestroy() {
    // antes de que el componente sea destruido, útil para limpieza

  },

  destroyed() {
    // después de que el componente ha sido destruido

  },

  methods: {
    // métodos
    ...mapActions('assetAssignment', ['getAssignmentReturn', 'getAssetAssignmentReturnCertificate', 'notificationAssetAssignmentReturnManual']),

    async getAssignmentReturnId() {
      const { assetAssignmentReturnId } = this.$route.params;
      this.loading = true;
      const { error } = await this.getAssignmentReturn(assetAssignmentReturnId);
      if (error) showError(error)
      this.loading = false;
    },

    async downloadCertificate() {
      try {
        this.loadingDownloadCertificateAssignment = true;
        const { ok, response } = await this.getAssetAssignmentReturnCertificate({ id: this.assetAssignmentReturn.id, data: Intl.DateTimeFormat().resolvedOptions().timeZone });
        if (ok) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'acta_devolución.pdf');
          document.body.appendChild(link);
          link.click();
        }
        this.loadingDownloadCertificateAssignment = false;
      } catch (error) {
        showError(error);
      }
    },

    async manualNotification() {
      const { error } = await this.notificationAssetAssignmentReturnManual({ id: this.assetAssignmentReturn?.id });
      if (error) showError(error);

      this.$swal({
        title: '¡Notificación enviada!',
        text: 'Te notificaremos por correo ¡Agradecemos tu paciencia!',
        icon: 'success',
        confirmButtonColor: '#0039a6',
      });
    },

    addUserSigner() {
      this.userSignerReturnDialog = true;
      this.$nextTick(() => {
        document.activeElement.blur();
      });
    },

    formatDate(dateString) {
      if (!dateString) {
        return '-';
      }
      return moment(dateString).format('DD/MM/YYYY HH:mm');
    },

    setColorStatusAssignmentReturn(status) {
      switch (status) {
        case 'PENDIENTE':
          return {
            background: 'light-blue lighten-5',
            color: 'light-blue',
            name: status,
          };
        case 'CONFIRMADO':
          return {
            background: 'green lighten-5',
            color: 'green',
            name: status,
          };
      }
    },
  },

  components: {
    // SignerUserDialog,
    SignerUserReturnDialog
  }
}