import { mapState, mapActions } from 'vuex';
import RegisterAssetMaintenance from '@/components/inventory/elements/RegisterAssetMaintenance/index.vue';
import { showError } from '@/helpers/globalHelpers';
import { returnStatus } from '@/helpers/globalValues';

export default {
	name: 'DetailReturnAsset',
	data() {
		return {
			isLoading: false,
			registerMaintenanceDialog: false,
			headers: [
				{
					text: 'Activo',
					align: 'start',
					sortable: false,
					value: 'asset',
				},
				{
					text: 'Número de serie',
					align: 'start',
					sortable: false,
					value: 'num_serie',
				},
			],
			items: [],
			confirmationTransferDialog: false,
		};
	},
	computed: {
		breadcrumbs() {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Inventario',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InventoryHome',
					},
				},
				{
					text: 'Activos',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'AssetsPermissions',
					},
				},
				{
					text: 'Detalle de Activo',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'DetailActiveInventory',
						params: { assetId: this.$route.params.assetId },
					},
				},
				{
					text: 'Detalle de Asignación',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'AssignmentAssetDetail',
						params: { assetId: this.$route.params.assetId },
					},
				},
				{
					text: 'Detalle de Devolución',
					link: true,
					exact: true,
					disabled: true,
				},
			];
		},
		...mapState('assetAssign', ['assetAssign']),
	},
	created() {
		// this.assetAssign
		this.getAssetReturn();
	},
	methods: {
		...mapActions('assetAssign', ['getAssetAssignmentById', 'cleanAssetAssign', 'getAssetReturnCertificate']),
		...mapActions('assetAssignment',['getAssetAssignmentReturnCertificate']),

		registerMaintenance() {
			this.registerMaintenanceDialog = true;
		},

		async getAssetReturn() {
			this.isLoading = true;

			const { assetAssignId } = this.$route.params;

			const { error } = await this.getAssetAssignmentById(assetAssignId);

			if (error) showError(error);

			this.isLoading = false;
		},

		getStatusColor(status) {
			switch (status) {
				case 'MOVILIZANDO':
					return 'blue accent-4';
				case 'CANCELADO':
					return 'red';
				case 'RECEPCIONADO':
				case 'RECEPCIONADO (*)':
					return 'green';
				case 'RECHAZADO':
					return 'blue-grey';
				default:
					return ''; // color por defecto o podrías retornar algún color en particular
			}
		},
		cancelTransfer() {
			this.$swal({
				text: '¿Estás seguro de cancelar este traslado?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#0039a6',
				cancelButtonColor: 'grey',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sí, cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.$router.push({ name: 'ListTransfer' });
				}
			});
		},
		declineTransfer() {
			this.$swal({
				text: '¿Estás seguro de rechazar este traslado?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#0039a6',
				cancelButtonColor: 'grey',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sí, rechazar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.$router.push({ name: 'ListTransfer' });
				}
			});
		},

		confirmTransfer() {
			this.confirmationTransferDialog = true;
		},

		getStateProperties(state) {
			const found = returnStatus.find((e) => e.key == state);
			return found || null;
		},

		async downloadCertificateAssetReturn() {
			try {
				// const { assetAssignId } = this.$route.params; // Asumiendo que obtienes el ID desde los parámetros de la ruta
				const { ok, response } = await this.getAssetAssignmentReturnCertificate({ id: this.assetAssign?.return?.asset_assignment_return?.id, data: Intl.DateTimeFormat().resolvedOptions().timeZone });
				if (ok) {
					const url = window.URL.createObjectURL(new Blob([response]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'acta_devolución.pdf'); // O el nombre que prefieras
					document.body.appendChild(link);
					link.click();
				}
			} catch (error) {
				showError(error);
				// Manejo de errores, por ejemplo mostrar un mensaje al usuario
			}
		}
	},
	beforeDestroy() {
		this.cleanAssetAssign();
	},
	components: {
		RegisterAssetMaintenance,
	},
};
