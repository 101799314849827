import { showError } from '@/helpers/globalHelpers';
import { mapActions } from 'vuex';

export default {
	name: 'CloseTicketDialog',
	data() {
		return {
			dialog: false,
			listError: [],
			valid: false,
			loadingSave: false,
		};
	},
	props: {
		value: Boolean,
		ticketId: [Number, String],
		listenCloseTicket: Function,
	},
	computed: {},
	created() {},
	methods: {
		...mapActions('ticket', ['closeTicket']),
		dialogInput(value) {
			this.$refs.button.$el.blur();
			this.dialog = value;
		},

		async closeTicketById() {
			this.loadingSave = true;
			const { ok, error } = await this.closeTicket(this.ticketId);
			if (ok) {
				this.listenCloseTicket();
				this.dialog = false;
			} else {
				showError(error);
			}
			this.loadingSave = false;
		},

		close() {
			this.dialog = false;
		},
	},
};
