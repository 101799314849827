import { showError } from '@/helpers/globalHelpers';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: 'ConfirmAssignment',
  data() {
    return {
      isLoading: false,
      isLoadingDecline: false,
      listError: [],
      dateSignature: null,
      lastSigner: null
    };
  },
  created() {
    this.getAssetAssignById();
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('assetAssign', ['assetAssign', 'assetAssignCertificate']),

    ...mapState('assetAssignment', ['assetAssignment']),

    lastSignerComputed() {
      return this.assetAssignment?.assetAssignSigner?.find(res =>
        res.type === 'ASIGNACION' && res.status === 'PENDIENTE'
      );
    },

    isPermitted() {
      return this.assetAssignment.assigned_user?.id === this.user.id || !!this.lastSignerComputed?.user_signer?.id;
    },

    isAssetPreassigned() {
      return this.assetAssignment?.assetAssignDetail?.every(assetAssign => assetAssign.status === 'PREASIGNADO') && this.assetAssignment?.status === 'PREASIGNADO';
    },

    isPreassignedAndPermitted() {
      return this.isAssetPreassigned && this.isPermitted;
    },

    isAssetAssigned() {
      return this.assetAssignment?.assetAssignDetail?.every(assetAssign => assetAssign.status === 'ASIGNADO') &&
        this.assetAssignment?.status === 'ASIGNADO';
    },

    isAssignmentDeclined() {
      return this.assetAssignment?.assetAssignDetail?.every(assetAssign => assetAssign.status === 'DECLINADO');
    },
  },
  methods: {
    ...mapActions('assetAssign', ['getAssetAssignCertificateById']),
    ...mapActions('assetAssignment', ['getAssignmentById','confirmPreassignmentAsset','declinePreassignmentAsset','getAssetAssignmentCertificateById']),

    async getAssetAssignById() {
      this.isLoading = true;
      this.dateSignature = moment().format();
      const { assetAssignId } = this.$route.params;

      const { error } = await this.getAssignmentById(assetAssignId);

      if (error) showError(error);

      this.isLoading = false;
    },

    formatDate(dateString) {
      if (!dateString) {
        return '-';
      }
      return moment(dateString).format('DD/MM/YYYY');
    },

    async confirmAssignment() {
      this.$swal({
        text: '¿Estás seguro de confirmar la asignación?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0039a6',
        cancelButtonColor: 'grey',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sí, confirmar',
      }).then(async result => {
        if (result.isConfirmed) {
          this.isLoading = true;
          // const { assetAssignId } = this.$route.params;
          const { error } = await this.confirmPreassignmentAsset({ id: this.assetAssignment?.id });
          if (error) showError(error);
          this.isLoading = false;
          this.dateSignature = null
          await this.getAssetAssignById()
        }
      });
    },

    async declinetAssignment() {
      this.$swal({
        text: '¿Estás seguro de declinar la asignación?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0039a6',
        cancelButtonColor: 'grey',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sí, declinar',
      }).then(async result => {
        if (result.isConfirmed) {
          this.isLoadingDecline = true;
          // const { assetAssignId } = this.$route.params;
          const { error } = await this.declinePreassignmentAsset({ id: this.assetAssignment?.id });
          if (error) showError(error);
          this.isLoadingDecline = false;
          this.dateSignature = null
          await this.getAssetAssignById()
        }
      });
    },

    async downloadCertificate() {
      try {
        // const { assetAssignId } = this.$route.params;
        const { ok, response } = await this.getAssetAssignmentCertificateById({ id: this.assetAssignment?.id, data: Intl.DateTimeFormat().resolvedOptions().timeZone });
        if (ok) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'acta_entrega.pdf');
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        showError(error);
      }
    }
  }

}