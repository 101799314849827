import { hasExtension } from '@/helpers/validationList';

export default {
	name: 'ImportFileIcon',
	props: {
		value: {
			type: [FileList, Array],
			required: false,
		},
	},
	data() {
		return {};
	},
	mounted() {},
	computed: {
		files: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		selectFile() {
			this.$refs.fileInput.click();
		},
		handleFileChange() {
			const currentFiles = this.$refs.fileInput?.files;

			if (currentFiles) {
				let tmpFiles = [];
				[...currentFiles].forEach((file) => {
					if (hasExtension(file, ['.png', '.jpg', '.jpge', '.gif'])) {
						tmpFiles.push(file);
					}
				});
				this.files = tmpFiles;
			}
			this.$refs.fileInput.value = '';
		},

		removeItem(index) {
			this.files.splice(index, 1);
		},
	},
};
