
import moment from 'moment';
export default {
    name: 'DetailAssignmentDialog',

    components: {

    },

    props: {
        value: Boolean,
        item: Object,
    },

    data() {
        return {
            dialog: false,

        }
    },

    computed: {

    },

    created() {

    },

    mounted() {

    },

    beforeDestroy() {

    },

    methods: {
        close() {
            this.dialog = false;
        },

        formatDate(dateString) {
            if (!dateString) {
                return '-';
            }
            return moment(dateString).format('DD/MM/YYYY HH:mm');
        },

        setColorStatusAssignment(status) {
            switch (status) {
              case 'PREASIGNADO':
                return {
                  background: 'light-blue lighten-5',
                  color: 'light-blue',
                  name: status,
                };
              case 'ANULADO':
                return {
                  background: 'red lighten-5',
                  color: 'red',
                  name: status,
                };
              case 'DECLINADO':
                return {
                  background: '#FFF3E0',
                  color: 'orange',
                  name: status,
                };
              case 'ASIGNADO':
                return {
                  background: 'green lighten-5',
                  color: 'green',
                  name: status,
                };
              case 'DESASIGNADO':
                return {
                  background: 'grey lighten-3',
                  color: 'grey',
                  name: status,
                };
              case 'DESASIGNADO_BAJA':
                return {
                  background: 'grey lighten-4',
                  color: 'grey',
                  name: 'DESASIGNADO-BAJA',
                };
      
            }
          },
    }
}