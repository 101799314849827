import { render, staticRenderFns } from "./AssignUserAssets.vue?vue&type=template&id=0b4c19f2&scoped=true&"
import script from "./AssignUserAssets.js?vue&type=script&lang=js&"
export * from "./AssignUserAssets.js?vue&type=script&lang=js&"
import style0 from "./AssignUserAssets.vue?vue&type=style&index=0&id=0b4c19f2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b4c19f2",
  null
  
)

export default component.exports