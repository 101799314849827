import { mapActions } from "vuex";

export default {
  name: "DeleteAssetDialog",
  data() {
    return {
      loadingSave: false,
      listError: [],
    }
  },
  props: {
    value: Boolean,
    item: Object,
    source: String,
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions("asset", ["deleteAsset"]),
    close() {
      this.dialog = false;
    },
    async deleteItemConfirm() {
      if (this.item) {
        this.loadingSave = true;
        const { ok,error } = await this.deleteAsset(this.item.id);

        if (ok) {
          this.close();
          switch (this.source) {
            case 'list':
              //await this.$emit('getAssets');
              //await this.$emit('closeCreateDialog');
              await this.$emit("closeDeleteDialog");
              break;
            case 'detail':
              this.$router.push({ name: "AssetsPermissions" });
              break;
          }
        } else {
          this.listError = Array.isArray(error?.data?.message)
          ? error?.data?.message
          : [error?.data?.message] || [];
        }
        this.loadingSave = false;
      }
    },
  },
};
